import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSun, FaDollarSign } from 'react-icons/fa';
import Usage from './Usage';

function HostDashboard() {
  const { t } = useTranslation();
  const [energyGenerated, setEnergyGenerated] = useState(0);
  const [energyConsumed, setEnergyConsumed] = useState(0);
  const [usageData, setUsageData] = useState([]);

  useEffect(() => {
    const fetchUsageData = async () => {
      // Simulated API call with monthly data for both production and consumption
      const mockUsageData = [
        { month: 'Jan', produced: 4500, consumed: 3800 },
        { month: 'Feb', produced: 4200, consumed: 3600 },
        { month: 'Mar', produced: 4800, consumed: 4000 },
        { month: 'Apr', produced: 5100, consumed: 4200 },
        { month: 'May', produced: 5500, consumed: 4500 },
        { month: 'Jun', produced: 6000, consumed: 4800 },
        { month: 'Jul', produced: 6300, consumed: 5000 },
        { month: 'Aug', produced: 6100, consumed: 4900 },
        { month: 'Sep', produced: 5800, consumed: 4700 },
        { month: 'Oct', produced: 5200, consumed: 4300 },
        { month: 'Nov', produced: 4700, consumed: 4000 },
        { month: 'Dec', produced: 4400, consumed: 3800 },
      ];
      setUsageData(mockUsageData);
      const totalProduced = mockUsageData.reduce((acc, data) => acc + data.produced, 0);
      const totalConsumed = mockUsageData.reduce((acc, data) => acc + data.consumed, 0);
      setEnergyGenerated(totalProduced);
      setEnergyConsumed(totalConsumed);
    };

    fetchUsageData();
  }, []);

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-tertiary text-base sm:text-base font-bold mb-6">{t('dashboard.host.welcome')}</h1>
      <p className="text-tertiary text-base sm:text-base mb-6">
        {t('dashboard.host.description')}
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="bg-secondary rounded-sm shadow-md p-4 sm:p-6">
          <div className="flex items-center mb-2 sm:mb-4">
            <FaSun className="text-yellow-400 text-lg sm:text-xl mr-2" />
            <h2 className="text-lg sm:text-xl font-semibold text-white">Total Energy Generated</h2>
          </div>
          <p className="text-2xl sm:text-4xl font-bold text-white">{energyGenerated} kWh</p>
        </div>
        <div className="bg-tertiary rounded-sm shadow-md p-4 sm:p-6">
          <div className="flex items-center mb-2 sm:mb-4">
            <FaDollarSign className="text-green-500 text-lg sm:text-xl mr-2" />
            <h2 className="text-lg sm:text-xl font-semibold text-white">Energy Consumed</h2>
          </div>
          <p className="text-2xl sm:text-4xl font-bold text-white">{energyConsumed} kWh</p>
        </div>
      </div>
      <div className="mt-6 p-4 rounded-sm bg-primary">
        <Usage usageData={usageData} />
      </div>
    </div>
  );
}

export default HostDashboard;
