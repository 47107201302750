import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function InvestmentProjections({ annualReturn, breakEven, profitAfter10Years }) {
  const data = [
    { name: 'Annual Return', value: annualReturn },
    { name: 'Break Even', value: breakEven },
    { name: 'Profit after 10 years', value: profitAfter10Years },
  ];

  return (
    <div className="bg-primary rounded-sm shadow-md p-4">
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip formatter={(value) => `${value.toLocaleString()} ${value === breakEven ? 'years' : '฿'}`} />
          <Legend />
          <Bar dataKey="value" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
      <div className="mt-4 grid grid-cols-3 gap-4 text-center">
        <div>
          <p className="text-sm font-medium text-gray-500">Annual Return</p>
          <p className="text-lg font-bold text-tertiary">{annualReturn.toLocaleString()} ฿</p>
        </div>
        <div>
          <p className="text-sm font-medium text-gray-500">Break Even</p>
          <p className="text-lg font-bold text-tertiary">{breakEven} years</p>
        </div>
        <div>
          <p className="text-sm font-medium text-gray-500">Profit after 10 years</p>
          <p className="text-lg font-bold text-tertiary">{profitAfter10Years.toLocaleString()} ฿</p>
        </div>
      </div>
    </div>
  );
}

export default InvestmentProjections;
