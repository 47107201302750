import React, { useState, useEffect } from 'react';
import { FaWallet, FaExchangeAlt, FaHistory } from 'react-icons/fa';

function Wallet() {
  const [balance, setBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    // Fetch wallet data from API
    fetchWalletData();
  }, []);

  const fetchWalletData = async () => {
    // Replace this with actual API call
    const mockBalance = 1500;
    const mockTransactions = [
      { id: 1, type: 'deposit', amount: 1000, date: '2023-08-01' },
      { id: 2, type: 'investment', amount: -500, date: '2023-08-02' },
      { id: 3, type: 'return', amount: 100, date: '2023-08-03' },
    ];

    setBalance(mockBalance);
    setTransactions(mockTransactions);
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl sm:text-3xl font-bold mb-6 text-tertiary">My Wallet</h1>
      
      <div className="bg-primary rounded-sm shadow-md p-6 mb-8">
        <div className="flex items-center mb-4">
          <FaWallet className="text-secondary text-3xl mr-4" />
          <div>
            <h2 className="text-xl font-semibold text-tertiary">Current Balance</h2>
            <p className="text-3xl font-bold text-secondary">${balance.toFixed(2)}</p>
          </div>
        </div>
        <div className="flex space-x-4">
          <button className="bg-secondary text-white px-4 py-2 rounded-sm hover:bg-opacity-90 transition duration-300">
            <FaExchangeAlt className="inline-block mr-2" />
            Add Funds
          </button>
          <button className="bg-tertiary text-white px-4 py-2 rounded-sm hover:bg-opacity-90 transition duration-300">
            <FaExchangeAlt className="inline-block mr-2" />
            Withdraw
          </button>
        </div>
      </div>

      <div className="bg-primary rounded-sm shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4 text-tertiary">
          <FaHistory className="inline-block mr-2" />
          Transaction History
        </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {transactions.map((transaction) => (
                <tr key={transaction.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">{transaction.type}</td>
                  <td className={`px-6 py-4 whitespace-nowrap text-sm font-medium ${
                    transaction.amount >= 0 ? 'text-green-600' : 'text-red-600'
                  }`}>
                    ${Math.abs(transaction.amount).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Wallet;
