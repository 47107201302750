import React, { useState, useEffect } from 'react';
import { FaFileInvoiceDollar } from 'react-icons/fa';

function HostInvoices() {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = async () => {
    // Simulated API call
    const mockInvoices = [
      { id: 1, date: '2023-08-01', amount: 500, status: 'Paid', usage: 1667, price: 0.3 },
      { id: 2, date: '2023-09-01', amount: 550, status: 'Pending', usage: 1833, price: 0.3 },
      { id: 3, date: '2023-10-01', amount: 600, status: 'Unpaid', usage: 2000, price: 0.3 },
    ];
    setInvoices(mockInvoices);
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-tertiary text-base sm:text-base font-bold mb-6">Manage and Track Your Invoices</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
        <div className="bg-secondary rounded-sm shadow-md p-4 sm:p-6">
          <div className="flex items-center mb-2 sm:mb-4">
            <FaFileInvoiceDollar className="text-white text-lg sm:text-xl mr-2" />
            <h2 className="text-lg sm:text-xl font-semibold text-white">Total Invoices</h2>
          </div>
          <p className="text-2xl sm:text-4xl font-bold text-white">{invoices.length}</p>
        </div>
        <div className="bg-tertiary rounded-sm shadow-md p-4 sm:p-6">
          <div className="flex items-center mb-2 sm:mb-4">
            <FaFileInvoiceDollar className="text-white text-lg sm:text-xl mr-2" />
            <h2 className="text-lg sm:text-xl font-semibold text-white">Total Amount</h2>
          </div>
          <p className="text-2xl sm:text-4xl font-bold text-white">
            ${invoices.reduce((sum, invoice) => sum + invoice.amount, 0)}
          </p>
        </div>
        <div className="bg-accent rounded-sm shadow-md p-4 sm:p-6">
          <div className="flex items-center mb-2 sm:mb-4">
            <FaFileInvoiceDollar className="text-white text-lg sm:text-xl mr-2" />
            <h2 className="text-lg sm:text-xl font-semibold text-white">Total Usage</h2>
          </div>
          <p className="text-2xl sm:text-4xl font-bold text-white">
            {invoices.reduce((sum, invoice) => sum + invoice.usage, 0)} kWh
          </p>
        </div>
      </div>

      <div className="bg-primary rounded-sm shadow-md overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-primary">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Invoice ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Usage (kWh)</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price (THB/kWh)</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount (THB)</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {invoices.map((invoice) => (
                <tr key={invoice.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{invoice.id}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.usage}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.price.toFixed(2)}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.amount}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-sm-full ${
                      invoice.status === 'Paid' ? 'bg-green-100 text-green-800' : 
                      invoice.status === 'Pending' ? 'bg-yellow-100 text-yellow-800' : 
                      'bg-red-100 text-red-800'
                    }`}>
                      {invoice.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default HostInvoices;
