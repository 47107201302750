import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function SupplyVsInvestor({ data }) {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-4 text-tertiary">Supply vs Investor Demand</h2>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis yAxisId="left" />
          <YAxis yAxisId="right" orientation="right" />
          <Tooltip />
          <Legend />
          <Line yAxisId="left" type="monotone" dataKey="supply" stroke="#8884d8" name="Energy Supply (kWh)" />
          <Line yAxisId="right" type="monotone" dataKey="demand" stroke="#82ca9d" name="Investor Demand (THB)" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default SupplyVsInvestor;