'use client'

import React, { useState, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, UserIcon, WalletIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { AuthContext } from '../contexts/AuthContext';

function Navigation() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isAuthenticated, userType, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  const hostNavigation = [
    { name: 'Host Dashboard', href: '/host-dashboard' },
    { name: 'Meter Reading', href: '/meter-reading' },
    { name: 'Plant Information', href: '/plant-information' },
    { name: 'Invoices', href: '/invoices' },
  ];

  const investorNavigation = [
    { name: 'Investor Dashboard', href: '/investor-dashboard' },
    { name: 'My Investments', href: '/my-investments' },
    { name: 'Payment Management', href: '/payment-management' },
  ];

  const adminNavigation = [
    { name: 'Admin Dashboard', href: '/admin-dashboard' },
    { name: 'User Management', href: '/user-management' },
    { name: 'Meter Reading Management', href: '/meter-reading-management' },
    { name: 'Payment Processing', href: '/admin-payment-processing' },
    { name: 'CRM', href: '/crm' },
  ];

  const navigation = 
    userType === 'host' ? hostNavigation :
    userType === 'investor' ? investorNavigation :
    userType === 'admin' ? adminNavigation :
    [];

  console.log('User Type:', userType);
  console.log('Navigation:', navigation);

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  // If not authenticated, don't render the navigation bar
  if (!isAuthenticated) {
    return null;
  }

  return (
    <header className="bg-primary">
      <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
        <div className="flex items-center gap-x-12">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">HelioGains</span>
            <img alt="" src="img/hg-color-logo.svg" className="h-8 w-auto" />
          </Link>
          {isAuthenticated && (
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`text-sm font-semibold leading-6 ${
                    isActive(item.href)
                      ? 'text-secondary'
                      : 'text-tertiary hover:text-secondary'
                  }`}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          )}
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-sm p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-6">
          {isAuthenticated && (
            <>
              <Link to="/profile" className="text-sm font-semibold leading-6 text-gray-900">
                <UserIcon className="h-6 w-6" />
              </Link>
              {isAuthenticated && userType === 'investor' && (
                <Link to="/wallet" className="text-sm font-semibold leading-6 text-gray-900 mr-4">
                  <WalletIcon className="h-6 w-6" />
                </Link>
              )}
              <button onClick={handleLogout} className="text-sm font-semibold leading-6 text-gray-900">
                <ArrowRightOnRectangleIcon className="h-6 w-6" />
              </button>
            </>
          )}
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src="img/heliogains.svg"
                alt=""
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-sm p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="-mx-3 block rounded-sm px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="py-6">
                <Link
                  to="/profile"
                  className="-mx-3 block rounded-sm px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <UserIcon className="h-6 w-6" />
                </Link>
                {isAuthenticated && userType === 'investor' && (
                  <Link
                    to="/wallet"
                    className="-mx-3 block rounded-sm px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Wallet
                  </Link>
                )}
                <button
                  onClick={() => {
                    handleLogout();
                    setMobileMenuOpen(false);
                  }}
                  className="-mx-3 block rounded-sm px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  <ArrowRightOnRectangleIcon className="h-6 w-6" />
                </button>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}

export default Navigation;