import React from 'react';
import { useTranslation } from 'react-i18next';

function CapacityPriceSlider({ capacity, setCapacity }) {
  const { t } = useTranslation();
  const handleSliderChange = (event) => {
    const newValue = Number(event.target.value);
    setCapacity(newValue);
  };

  const handleSliderClick = (event) => {
    const slider = event.currentTarget;
    const rect = slider.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const percentage = x / rect.width;
    const newValue = Math.round(percentage * 500);
    setCapacity(newValue);
  };

  return (
    <div className="py-6">
      <div className="mb-4 relative">
        <input
          type="range"
          min="0"
          max="30"
          value={capacity}
          onChange={handleSliderChange}
          onClick={handleSliderClick}
          className="w-full h-2 bg-primary rounded-sm appearance-none cursor-pointer accent-accent"
        />
      </div>
      <div className="flex justify-between text-sm text-gray-600">
        <span>0.00 {t('common.units.kw')}</span>
        <span>30.00 {t('common.units.kw')}</span>
      </div>
    </div>
  );
}

export default CapacityPriceSlider;
