import React, { useEffect } from 'react';
import SimpleHeader from '../components/SimpleHeader';
import { trackClick } from '../utils/analytics';

function Privacy() {
  useEffect(() => {
    trackClick('privacy');
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <SimpleHeader />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-6 text-tertiary">Privacy Policy</h1>
        <div className="bg-white rounded-sm shadow-md p-6">
          <h2 className="text-xl font-semibold mb-4 text-secondary">1. Information We Collect</h2>
          <p className="mb-4">
            We collect information to provide better services to our users and maintain the security of our platform.
          </p>

          <h2 className="text-xl font-semibold mb-4 text-secondary">2. How We Use Information</h2>
          <p className="mb-4">
            We use the information we collect to operate and improve our services, develop new features, and protect our users.
          </p>

          <h2 className="text-xl font-semibold mb-4 text-secondary">3. Information Security</h2>
          <p className="mb-4">
            We work hard to protect our users from unauthorized access to or unauthorized alteration, disclosure, or destruction of information we hold.
          </p>

          <h2 className="text-xl font-semibold mb-4 text-secondary">4. Data Retention</h2>
          <p className="mb-4">
            We retain personal data only for as long as necessary to provide the Services you have requested and thereafter for legitimate legal or business purposes.
          </p>

          <h2 className="text-xl font-semibold mb-4 text-secondary">5. Your Rights</h2>
          <p className="mb-4">
            You have the right to access, correct, or delete your personal information. Contact us to exercise these rights.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
