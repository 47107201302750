import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie'; // You'll need to install this package: npm install js-cookie
import { BASE_URL } from '../config';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    const authToken = Cookies.get('authToken');
    const userType = Cookies.get('userType');
    if (authToken && userType) {
      setIsAuthenticated(true);
      setUserType(userType);
    }
  }, []);

  const login = async (email, password, type = 'user') => {

    try {
      const response = await fetch(`${BASE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, type }),
      });

      if (!response.ok) {
        throw new Error('Login failed');
      }

      const data = await response.json();

      setIsAuthenticated(true);
      setUserType(data.user_type);
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('userType', data.user_type);

      return data.user_type;
    } catch (error) {
      console.error('Login error:', error);
      throw new Error('Invalid credentials');
    }
  };

  const logout = async () => {
    try {
      const token = Cookies.get('authToken');
      const response = await fetch(`${BASE_URL}/logout`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Logout failed');
      }

      // Clear authentication state
      setIsAuthenticated(false);
      setUserType(null);

      // Remove cookie
      Cookies.remove('authToken');

    } catch (error) {
      console.error('Logout error:', error);
      // Even if the server request fails, we should still clear the local auth state
      setIsAuthenticated(false);
      setUserType(null);
      Cookies.remove('authToken');
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userType, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// We're not exporting AuthProvider as default, so we'll keep it as a named export
