import React, { useState, useEffect } from 'react';
import UserList from '../components/UserList';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

function UserManagement() {
  const [users, setUsers] = useState([]);  // Initialize as an empty array

  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    role: '',
    phone: '',
    dateOfBirth: '',
    accountNumber: '',
    bankName: '',
    bankCode: ''
  });
  const [errors, setErrors] = useState({});
  const [editingUser, setEditingUser] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [expandedSections, setExpandedSections] = useState({
    personal: true,
    bank: true  // Changed this to true
  });

  useEffect(() => {
    // Fetch users from your API
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    // Simulated API call
    const mockUsers = [
      { 
        id: 1, 
        name: 'John Doe', 
        email: 'john@example.com', 
        role: 'host', 
        phone: '1234567890', 
        dateOfBirth: '1990-01-01',
        accountNumber: '1234567890',
        bankName: 'Bangkok Bank',
        bankCode: 'BBL'
      },
      { 
        id: 2, 
        name: 'Jane Smith', 
        email: 'jane@example.com', 
        role: 'investor', 
        phone: '9876543210', 
        dateOfBirth: '1985-05-15',
        accountNumber: '0987654321',
        bankName: 'Kasikorn Bank',
        bankCode: 'KBANK'
      },
      { 
        id: 3, 
        name: 'Host User', 
        email: 'host@example.com', 
        role: 'host', 
        phone: '5555555555', 
        dateOfBirth: '1970-12-31',
        accountNumber: '1122334455',
        bankName: 'Siam Commercial Bank',
        bankCode: 'SCB'
      },
      {
        id: 4,
        name: 'Sarah Johnson',
        email: 'sarah@example.com',
        role: 'investor',
        phone: '2223334444',
        dateOfBirth: '1988-07-20',
        accountNumber: '9876543210',
        bankName: 'Krung Thai Bank',
        bankCode: 'KTB'
      },
      {
        id: 5,
        name: 'Michael Chen',
        email: 'michael@example.com',
        role: 'host',
        phone: '3334445555',
        dateOfBirth: '1992-03-10',
        accountNumber: '5432167890',
        bankName: 'Bangkok Bank',
        bankCode: 'BBL'
      },
      {
        id: 6,
        name: 'Emily Wong',
        email: 'emily@example.com',
        role: 'investor',
        phone: '4445556666',
        dateOfBirth: '1983-11-05',
        accountNumber: '6789054321',
        bankName: 'Kasikorn Bank',
        bankCode: 'KBANK'
      },
      {
        id: 7,
        name: 'David Lee',
        email: 'david@example.com',
        role: 'investor',
        phone: '5556667777',
        dateOfBirth: '1995-09-15',
        accountNumber: '3456789012',
        bankName: 'Siam Commercial Bank',
        bankCode: 'SCB'
      },
      {
        id: 8,
        name: 'Lisa Park',
        email: 'lisa@example.com',
        role: 'investor',
        phone: '6667778888',
        dateOfBirth: '1987-02-28',
        accountNumber: '7890123456',
        bankName: 'Krung Thai Bank',
        bankCode: 'KTB'
      },
      {
        id: 9,
        name: 'Tom Wilson',
        email: 'tom@example.com',
        role: 'host',
        phone: '7778889999',
        dateOfBirth: '1993-06-22',
        accountNumber: '2345678901',
        bankName: 'Bangkok Bank',
        bankCode: 'BBL'
      },
      {
        id: 10,
        name: 'Anna Brown',
        email: 'anna@example.com',
        role: 'investor',
        phone: '8889990000',
        dateOfBirth: '1991-04-17',
        accountNumber: '8901234567',
        bankName: 'Kasikorn Bank',
        bankCode: 'KBANK'
      }
    ];
    setUsers(mockUsers);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      // Only allow numeric input for phone
      const numericValue = value.replace(/\D/g, '');
      setNewUser({ ...newUser, [name]: numericValue });
    } else {
      setNewUser({ ...newUser, [name]: value });
    }
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingUser) {
      handleUpdateUser();
    } else {
      handleAddUser();
    }
  };

  const handleAddUser = () => {
    // Simulated API call to add user
    const addedUser = { 
      ...newUser, 
      id: users.length + 1,
    };
    setUsers([...users, addedUser]);
    setNewUser({ 
      name: '', email: '', role: '', phone: '', dateOfBirth: '',
      accountNumber: '', bankName: '', bankCode: ''
    });
    setExpandedSections({ personal: true, bank: true });
    setConfirmationMessage('User has been successfully created');
    setTimeout(() => setConfirmationMessage(''), 3000);
  };

  const handleUpdateUser = () => {
    // Update existing user
    const updatedUsers = users.map(user => 
      user.id === newUser.id ? newUser : user
    );
    setUsers(updatedUsers);
    setEditingUser(false);
    setNewUser({
      name: '', email: '', role: '', phone: '', dateOfBirth: '',
      accountNumber: '', bankName: '', bankCode: ''
    });
    setExpandedSections({ personal: true, bank: true });
    setConfirmationMessage('User updated successfully');
    setTimeout(() => setConfirmationMessage(''), 3000);
  };

  const handleEditUser = (user) => {
    setEditingUser(true);
    setNewUser({
      id: user.id,
      name: user.name,
      email: user.email,
      role: user.role,
      phone: user.phone,
      dateOfBirth: user.dateOfBirth,
      // Add bank details
      accountNumber: user.accountNumber || '',
      bankName: user.bankName || '',
      bankCode: user.bankCode || ''
    });
    // Expand both sections when editing
    setExpandedSections({
      personal: true,
      bank: true
    });
  };

  const handleDeleteUser = async (userId) => {
    // Simulated API call to delete user
    const updatedUsers = users.filter((user) => user.id !== userId);
    setUsers(updatedUsers);
    setConfirmationMessage('User has been successfully deleted');
    setTimeout(() => setConfirmationMessage(''), 3000);
  };

  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

  const toggleSection = (section) => {
    setExpandedSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  return (
    <div className="container mx-auto px-4 py-6 sm:py-8">
      <h1 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-tertiary">User Management</h1>
      
      {confirmationMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-sm relative mb-4" role="alert">
          <span className="block sm:inline">{confirmationMessage}</span>
        </div>
      )}
      
      {/* Add/Edit User Form */}
      <div className="bg-white mb-6">
        <h2 className="text-lg font-semibold mb-4 text-tertiary">
          {editingUser ? 'Edit User' : 'Add New User'}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4 lg:space-y-0 lg:flex lg:gap-6">
          {/* Personal Information */}
          <div className="lg:flex-1">
            <div className="border border-gray-200 rounded-sm">
              <button
                type="button"
                className="flex justify-between items-center w-full px-4 py-2 text-left text-sm font-medium text-gray-700 bg-primary hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-secondary"
                onClick={() => toggleSection('personal')}
              >
                <span>Personal Information</span>
                {expandedSections.personal ? (
                  <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                )}
              </button>
              {expandedSections.personal && (
                <div className="px-4 py-3 space-y-3">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={newUser.name}
                      onChange={handleInputChange}
                      className="block w-full border border-gray-300 rounded-sm shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-secondary sm:text-sm"
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={newUser.email}
                      onChange={handleInputChange}
                      className="block w-full border border-gray-300 rounded-sm shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-secondary sm:text-sm"
                    />
                  </div>
                  <div>
                    <label htmlFor="role" className="block text-sm font-medium text-gray-700 mb-1">Role</label>
                    <select
                      id="role"
                      name="role"
                      value={newUser.role}
                      onChange={handleInputChange}
                      className="block w-full border border-gray-300 rounded-sm shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-secondary sm:text-sm"
                      required
                    >
                      <option value="">Select a role</option>
                      <option value="host">Host</option>
                      <option value="investor">Investor</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={newUser.phone}
                      onChange={handleInputChange}
                      pattern="[0-9]*"
                      inputMode="numeric"
                      maxLength="10"
                      className={`block w-full border ${errors.phone ? 'border-red-500' : 'border-gray-300'} rounded-sm shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-secondary sm:text-sm`}
                    />
                    {errors.phone && <p className="mt-1 text-sm text-red-500">{errors.phone}</p>}
                  </div>
                  <div>
                    <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700 mb-1">Date of Birth</label>
                    <input
                      type="date"
                      id="dateOfBirth"
                      name="dateOfBirth"
                      value={newUser.dateOfBirth}
                      onChange={handleDateChange}
                      max={today}
                      className={`block w-full border ${errors.dateOfBirth ? 'border-red-500' : 'border-gray-300'} rounded-sm shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-secondary sm:text-sm`}
                    />
                    {errors.dateOfBirth && <p className="mt-1 text-sm text-red-500">{errors.dateOfBirth}</p>}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Bank Details */}
          <div className="lg:flex-1">
            <div className="border border-gray-200 rounded-sm">
              <button
                type="button"
                className="flex justify-between items-center w-full px-4 py-2 text-left text-sm font-medium text-gray-700 bg-primary hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-secondary"
                onClick={() => toggleSection('bank')}
              >
                <span>Bank Details</span>
                {expandedSections.bank ? (
                  <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                )}
              </button>
              {expandedSections.bank && (
                <div className="px-4 py-3 space-y-3">
                  <div>
                    <label htmlFor="accountNumber" className="block text-sm font-medium text-gray-700 mb-1">Account Number</label>
                    <input
                      type="text"
                      id="accountNumber"
                      name="accountNumber"
                      value={newUser.accountNumber}
                      onChange={handleInputChange}
                      className="block w-full border border-gray-300 rounded-sm shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-secondary sm:text-sm"
                    />
                  </div>
                  <div>
                    <label htmlFor="bankName" className="block text-sm font-medium text-gray-700 mb-1">Bank Name</label>
                    <input
                      type="text"
                      id="bankName"
                      name="bankName"
                      value={newUser.bankName}
                      onChange={handleInputChange}
                      className="block w-full border border-gray-300 rounded-sm shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-secondary sm:text-sm"
                    />
                  </div>
                  <div>
                    <label htmlFor="bankCode" className="block text-sm font-medium text-gray-700 mb-1">Bank Code</label>
                    <input
                      type="text"
                      id="bankCode"
                      name="bankCode"
                      value={newUser.bankCode}
                      onChange={handleInputChange}
                      className="block w-full border border-gray-300 rounded-sm shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-secondary sm:text-sm"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        <div className="flex justify-end mt-4">
          <button
            type="submit"
            onClick={handleSubmit}
            className="bg-secondary text-white py-2 px-4 rounded-sm shadow-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary transition duration-150 ease-in-out"
          >
            {editingUser ? 'Update User' : 'Add User'}
          </button>
        </div>
      </div>
      
      {/* User List */}
      <div className="bg-primary rounded-sm shadow-md p-4 sm:p-6">
        <UserList users={users} handleEditUser={handleEditUser} handleDeleteUser={handleDeleteUser} />
      </div>
    </div>
  );
}

export default UserManagement;