import React, { useState, useMemo } from 'react';
import { MagnifyingGlassIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import 'animate.css';

const UserList = ({ users, handleEditUser, handleDeleteUser }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [expandedCard, setExpandedCard] = useState(null);
  const [filter, setFilter] = useState('all'); // Default filter is 'all'
  const [searchTerm, setSearchTerm] = useState('');
  const [animationClass, setAnimationClass] = useState('');

  const initiateDelete = (user) => {
    setUserToDelete(user);
    setShowConfirmation(true);
  };

  const confirmDelete = () => {
    handleDeleteUser(userToDelete.id);
    setShowConfirmation(false);
    setUserToDelete(null);
  };

  const cancelDelete = () => {
    setShowConfirmation(false);
    setUserToDelete(null);
  };

  const toggleCard = (userId) => {
    if (expandedCard === userId) {
      setAnimationClass('animate__animated animate__fadeOut');
      setTimeout(() => {
        setExpandedCard(null);
        setAnimationClass('');
      }, 500); // Match this to the animation duration
    } else {
      setExpandedCard(userId);
      setAnimationClass('animate__animated animate__fadeIn');
    }
  };

  const filteredUsers = useMemo(() => {
    return users.filter(user => {
      const roleMatch = filter === 'all' || user.role.toLowerCase() === filter;
      const searchMatch = user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          user.email.toLowerCase().includes(searchTerm.toLowerCase());
      return roleMatch && searchMatch;
    });
  }, [users, filter, searchTerm]);

  return (
    <div className="">
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-xl font-semibold">User List</h2>
        
        <div className="flex items-center space-x-4">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="text"
              placeholder="Search by name or email"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4 py-2 border rounded-md w-64 focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
            />
          </div>
          
          <div className="inline-flex rounded-md shadow-sm" role="group">
            <button
              type="button"
              onClick={() => setFilter('all')}
              className={`px-4 py-2 text-sm font-medium rounded-l-lg ${
                filter === 'all'
                  ? 'bg-secondary text-white'
                  : 'bg-white text-gray-700 hover:bg-gray-50'
              }`}
            >
              All
            </button>
            <button
              type="button"
              onClick={() => setFilter('host')}
              className={`px-4 py-2 text-sm font-medium ${
                filter === 'host'
                  ? 'bg-secondary text-white'
                  : 'bg-white text-gray-700 hover:bg-gray-50'
              }`}
            >
              Hosts
            </button>
            <button
              type="button"
              onClick={() => setFilter('investor')}
              className={`px-4 py-2 text-sm font-medium rounded-r-lg ${
                filter === 'investor'
                  ? 'bg-secondary text-white'
                  : 'bg-white text-gray-700 hover:bg-gray-50'
              }`}
            >
              Investors
            </button>
          </div>
        </div>
      </div>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {filteredUsers.map((user) => (
          <div
            key={user.id}
            className={`bg-white p-6 rounded-sm shadow-md hover:shadow-lg transition-all duration-300 hover:bg-gray-50 cursor-pointer relative group ${
              expandedCard === user.id ? 'ring-2 ring-accent bg-gray-50' : ''
            }`}
            onClick={() => toggleCard(user.id)}
          >
            <div className="flex items-center justify-between mb-4 rounded-sm¬">
              <div className="flex items-center">
                <div className="w-12 h-12 bg-secondary group-hover:bg-tertiary rounded-full flex items-center justify-center text-white font-bold text-xl mr-4 transition-colors duration-300">
                  {user.name.charAt(0)}
                </div>
                <div>
                  <h3 className="font-semibold text-lg text-tertiary">{user.name}</h3>
                  <p className="text-sm text-gray-600">{user.role}</p>
                </div>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditUser(user);
                  }}
                  className="text-secondary hover:text-tertiary transition-colors duration-300"
                >
                  <PencilIcon className="h-5 w-5" />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    initiateDelete(user);
                  }}
                  className="text-red-500 hover:text-red-600 transition-colors duration-300"
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
            {expandedCard === user.id && (
              <div className={`absolute z-10 left-0 right-0 top-full mt-2 bg-white p-6 rounded-sm shadow-xl border border-gray-200 ${animationClass}`}>
                <div className="space-y-2 text-sm text-gray-600">
                  <p><span className="font-medium">Email:</span> {user.email}</p>
                  <p><span className="font-medium">Phone:</span> {user.phone}</p>
                  <p><span className="font-medium">DOB:</span> {user.dateOfBirth}</p>
                  <p><span className="font-medium">Account:</span> {user.accountNumber}</p>
                  <p><span className="font-medium">Bank:</span> {user.bankName}</p>
                  <p><span className="font-medium">Bank Code:</span> {user.bankCode}</p>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      {showConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-sm shadow-xl max-w-md w-full">
            <h3 className="text-xl font-bold mb-4 text-tertiary">Confirm Deletion</h3>
            <p className="mb-6 text-gray-600">Are you sure you want to delete the user {userToDelete?.name}?</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={cancelDelete}
                className="px-6 py-2 bg-gray-200 text-gray-800 rounded-sm hover:bg-gray-300 transition-colors duration-300"
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                className="px-6 py-2 bg-red-500 text-white rounded-sm hover:bg-red-600 transition-colors duration-300"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserList;