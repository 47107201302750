import React, { useState, useEffect } from 'react';
import StatisticsBox from '../components/StatisticsBox';
import ProjectTypesDistribution from '../components/ProjectTypesDistribution';
import InvestorGrowth from '../components/InvestorGrowth';
import MonthlyRevenue from '../components/MonthlyRevenue';
import MonthlyEnergy from '../components/MonthlyEnergy';
import SupplyVsInvestor from '../components/SupplyVsInvestor';
import { fetchDashboardData, calculateSupplyVsDemandData } from '../utils/dashboardData';

function AdminDashboard() {
  const [dashboardData, setDashboardData] = useState({
    totalEnergyGenerated: 0,
    totalRevenue: 0,
    totalInvestors: 0,
    totalHouseholds: 0,
    projectTypes: [],
    monthlyEnergy: [],
    monthlyRevenue: [],
    investorGrowth: []
  });

  const [supplyVsDemandData, setSupplyVsDemandData] = useState([]);

  useEffect(() => {
    // Fetch dashboard data from API
    const loadDashboardData = async () => {
      const data = await fetchDashboardData();
      setDashboardData(data);
      setSupplyVsDemandData(calculateSupplyVsDemandData());
    };
    loadDashboardData();
  }, []);

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 text-tertiary">Dashboard</h1>
      
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mb-8">
        <StatisticsBox
          title={'Total Energy Generated'}
          value={'6009 kWh'}
        />
      </div>

      <div className="grid gap-8 grid-cols-1 mb-8">
        <div className="bg-white p-4 rounded-sm shadow">
          <SupplyVsInvestor data={supplyVsDemandData} />
        </div>
      </div>

      <div className="grid gap-8 grid-cols-1 lg:grid-cols-2 mb-8">
        <div className="bg-white p-4 rounded-sm shadow-md">
          <MonthlyEnergy data={dashboardData.monthlyEnergy} />
        </div>
        <div className="bg-white p-4 rounded-sm shadow-md">
          <MonthlyRevenue data={dashboardData.monthlyRevenue} />
        </div>
      </div>
      <div className="grid gap-8 grid-cols-1 lg:grid-cols-2 mb-8">
        <div className="bg-white p-4 rounded-sm shadow-md">
          <InvestorGrowth dashboardData={dashboardData} />
        </div>
        <div className="bg-white p-4 rounded-sm shadow-md">
          <ProjectTypesDistribution dashboardData={dashboardData} />
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;