import React from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

function InvestmentPerformanceChart({ data }) {
  return (
    <div className="bg-primary rounded-sm shadow-md p-2 sm:p-4">
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis yAxisId="left" />
          <YAxis yAxisId="right" orientation="right" />
          <Tooltip />
          <Legend />
          <Line yAxisId="left" type="monotone" dataKey="capacity" stroke="#82BF53" name="Capacity (kWh)" />
          <Line yAxisId="right" type="monotone" dataKey="revenue" stroke="#1e3c63" name="Revenue ($)" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default InvestmentPerformanceChart;
