import React, { useContext, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import SimpleHeader from './components/SimpleHeader';
import { AuthContext, AuthProvider } from './contexts/AuthContext';
import { ModalProvider } from './contexts/ModalContext';
import Navigation from './components/Navigation';
import Login from './components/Login';
import SignUp from './components/SignUp';
import HostDashboard from './components/HostDashboard';
import InvestorDashboard from './components/InvestorDashboard';
import AdminDashboard from './pages/AdminDashboard';
import Home from './pages/Home';
import ProtectedRoute from './components/ProtectedRoute';
import PaymentManagement from './components/PaymentManagement';
import MeterReading from './components/MeterReading';
import Wallet from './components/Wallet';
import HostInvoices from './components/HostInvoices';
import PlantInformation from './components/PlantInformation';
import Usage from './components/Usage';
import MeterReadingManagement from './components/MeterReadingManagement';
import UserManagement from './pages/UserManagement';
import AdminPaymentProcessing from './components/AdminPaymentProcessing';
import MyInvestments from './components/MyInvestments';
import CapacitySelector from './components/CapacitySelector';
import NotFound from './components/NotFound';
import Verified from './pages/Verified';
import CRM from './components/CRM';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';

function AppContent() {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  const hideNavigation = ['/login', '/signup', '/', '/admin-login'].includes(location.pathname);

  useEffect(() => {
    if (isAuthenticated) {
      document.body.classList.add('logged-in');
    } else {
      document.body.classList.remove('logged-in');
    }
  }, [isAuthenticated]);

  return (
    <div className="h-screen bg-white flex flex-col">
      <SimpleHeader />
      {isAuthenticated && !hideNavigation ? <Navigation /> : null}
      <div className="flex-grow">
        <Routes>
          <Route path="/" element={<Navigate to="/en" replace />} />
          <Route path="/en" element={<Home />} />
          <Route path="/th" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route 
            path="/host-dashboard" 
            element={
              <ProtectedRoute allowedUserTypes={['host', 'admin']}>
                <HostDashboard />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/investor-dashboard" 
            element={
              <ProtectedRoute allowedUserTypes={['investor']}>
                <InvestorDashboard />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/admin-dashboard" 
            element={
              <ProtectedRoute allowedUserTypes={['admin']}>
                <AdminDashboard />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/payment-management" 
            element={
              <ProtectedRoute allowedUserTypes={['investor', 'admin']}>
                <PaymentManagement />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/meter-reading" 
            element={
              <ProtectedRoute allowedUserTypes={['host', 'admin']}>
                <MeterReading />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/wallet" 
            element={
              <ProtectedRoute allowedUserTypes={['investor']}>
                <Wallet />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/invoices" 
            element={
              <ProtectedRoute allowedUserTypes={['host', 'admin']}>
                <HostInvoices />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/plant-information" 
            element={
              <ProtectedRoute allowedUserTypes={['host', 'admin']}>
                <PlantInformation />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/usage" 
            element={
              <ProtectedRoute allowedUserTypes={['host', 'admin']}>
                <Usage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/meter-reading-management" 
            element={<MeterReadingManagement />} 
          />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/admin-payment-processing" element={<AdminPaymentProcessing />} />
          <Route 
            path="/my-investments" 
            element={
              <ProtectedRoute allowedUserTypes={['investor']}>
                <MyInvestments />
              </ProtectedRoute>
            } 
          />
          <Route path="/verified" element={<Verified />} />
          <Route path="/capacity-selector" element={<CapacitySelector capacity={50} setCapacity={() => {}} />} />
          <Route 
            path="/crm" 
            element={
              <ProtectedRoute allowedUserTypes={['admin']}>
                <CRM />
              </ProtectedRoute>
            } 
          />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <ModalProvider>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </ModalProvider>
  );
}

export default App;
