import React, { useState, useEffect } from 'react';
import { FaSun, FaDollarSign, FaLeaf, FaTree, FaHome, FaGasPump, FaCar, FaLightbulb, FaWater } from 'react-icons/fa';
import CapacityPriceSlider from './CapacityPriceSlider';
import InvestmentProjections from './InvestmentProjections';
import InvestmentPerformanceChart from './InvestmentPerformanceChart';
import { generateInvoice } from '../utils/invoiceUtils';
import InvoiceDisplay from './InvoiceDisplay';

function InvestorDashboard() {
  const [investmentData, setInvestmentData] = useState({
    totalEnergyGenerated: 0,
    revenue: 0,
  });
  const [solarStats, setSolarStats] = useState({
    co2Reduced: 0,
    treesPlanted: 0,
    householdsPowered: 0,
    fuelSaved: 0,
    carsOffRoad: 0,
    waterSaved: 0,
    lightbulbsReplaced: 0,
    carbonFootprintReduced: 0,
  });
  const [annualReturn, setAnnualReturn] = useState(0);
  const [breakEven, setBreakEven] = useState(0);
  const [profitAfter10Years, setProfitAfter10Years] = useState(0);
  const [capacity, setCapacity] = useState(5); // Default capacity in kW
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    // Simulating API call to fetch investor dashboard data
    const fetchInvestorData = async () => {
      // Replace this with actual API call
      const mockData = {
        totalCapacityPurchased: 200, // in kW
        revenue: 1200, // in dollars
      };
      setInvestmentData(mockData);

      // Mock data for solar stats
      const mockSolarStats = {
        co2Reduced: 350, // in kg
        treesPlanted: 15,
        householdsPowered: 50,
        fuelSaved: 40, // in gallons
        carsOffRoad: 2,
        waterSaved: 1000, // in liters
        lightbulbsReplaced: 100,
        carbonFootprintReduced: 0.5, // in tons
      };
      setSolarStats(mockSolarStats);

      const mockProjections = {
        annualReturn: 15000,
        breakEven: 3.5,
        profitAfter10Years: 150000,
      };
      setAnnualReturn(mockProjections.annualReturn);
      setBreakEven(mockProjections.breakEven);
      setProfitAfter10Years(mockProjections.profitAfter10Years);
    };

    fetchInvestorData();
  }, []);

  const mockGraphData = [
    { "month": "Jan", "capacity": 16, "revenue": 443.52 },
    { "month": "Feb", "capacity": 20, "revenue": 554.40 },
    { "month": "Mar", "capacity": 10, "revenue": 277.20 },
    { "month": "Apr", "capacity": 15, "revenue": 415.80 },
    { "month": "May", "capacity": 13, "revenue": 360.36 },
    { "month": "Jun", "capacity": 18, "revenue": 498.96 },
    { "month": "Jul", "capacity": 12, "revenue": 332.64 },
    { "month": "Aug", "capacity": 17, "revenue": 471.24 },
    { "month": "Sep", "capacity": 20, "revenue": 554.40 },
    { "month": "Oct", "capacity": 22, "revenue": 609.84 },
    { "month": "Nov", "capacity": 20, "revenue": 554.40 },
    { "month": "Dec", "capacity": 17, "revenue": 471.24 }
  ];

  const handleBuyCapacity = () => {
    const invoiceData = {
      seller: {
        name: "HelioGains Solar Company",
        address: "123 Solar Street, Bangkok, Thailand",
      },
      buyer: {
        name: "John Doe", // Replace with actual investor name
        address: "456 Investor Avenue, Bangkok, Thailand", // Replace with actual investor address
      },
      totalCapacity: capacity,
      totalAmount: capacity * 18000,
      invoiceDate: new Date().toISOString().split('T')[0],
      invoiceTermDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    };

    // Set the invoice data to state, which will trigger the display
    setInvoice(invoiceData);

    // Call the function to generate and save the invoice
    generateInvoice(invoiceData);
  };

  // Add this function to close the invoice display
  const handleCloseInvoice = () => {
    setInvoice(null);
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-8">
      <h1 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-tertiary">Dashboard</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
        <div className="bg-secondary rounded-sm shadow-md p-4 sm:p-6 border-l-4 border-accent">
          <div className="flex items-center mb-2 sm:mb-4">
            <FaSun className="text-white text-lg sm:text-xl mr-2" />
            <h2 className="text-lg sm:text-xl font-semibold text-white">Total Capacity Purchased</h2>
          </div>
          <p className="text-2xl sm:text-4xl font-bold text-white">{investmentData.totalCapacityPurchased} kW</p>
        </div>
        <div className="bg-tertiary rounded-sm shadow-md p-4 sm:p-6 border-l-4 border-accent">
          <div className="flex items-center mb-2 sm:mb-4">
            <FaDollarSign className="text-white text-lg sm:text-xl mr-2" />
            <h2 className="text-lg sm:text-xl font-semibold text-white">Revenue Earned</h2>
          </div>
          <p className="text-2xl sm:text-4xl font-bold text-white">${investmentData.revenue}</p>
        </div>
      </div>
      <div className="rounded-sm shadow-md p-4 sm:p-6 mt-6 border-t-2 border-accent">
        <h2 className="text-lg sm:text-xl font-bold mb-3 sm:mb-4 text-tertiary">Buy More Capacity</h2>
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 pr-4">
            <p className="text-sm sm:text-base text-gray-700 mb-4">
              You can buy more capacity to increase your revenue and impact. Slide to select the amount of capacity you want to purchase.
            </p>
            <div className="bg-gray-100 p-4 rounded-sm border-l-2 border-secondary">
              <p className="text-sm font-semibold mb-2">Price per 1 kW:</p>
              <p className="text-lg font-bold text-tertiary">18,000 THB</p>
            </div>
          </div>
          <div className="md:w-1/2 mt-4 md:mt-0">
            <CapacityPriceSlider capacity={capacity} setCapacity={setCapacity} />
            <div className="mt-4 bg-gray-100 p-4 rounded-sm border-l-2 border-tertiary">
              <div className="flex justify-between mb-2">
                <span className="font-semibold">Total Capacity:</span>
                <span className="font-bold text-tertiary">{capacity} kW</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold">Total Amount:</span>
                <span className="font-bold text-tertiary">{(capacity * 18000).toLocaleString()} THB</span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 text-center">
          <button
            onClick={handleBuyCapacity}
            className="bg-tertiary text-white px-6 py-2 rounded-sm w-full sm:w-auto hover:bg-opacity-80 transition duration-300 ease-in-out transform hover:-translate-y-1"
          >
            Buy Capacity
          </button>
        </div>
      </div>
        
      <div className="mt-6 sm:mt-8">
        <h2 className="text-lg sm:text-xl font-bold mb-3 sm:mb-4 text-tertiary">Investment Details</h2>
        <p className="text-sm sm:text-base text-gray-700 mb-4 border-l-4 border-accent pl-4">
          Your investment has generated a total of {investmentData.totalCapacityPurchased} kW of energy, resulting in ${investmentData.revenue} in revenue.
          Based on current trends, your projected future returns are estimated at $.
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="bg-white border border-gray-200 rounded-sm shadow-sm">
            <h3 className="text-md font-semibold mb-2 text-tertiary bg-gray-50 p-3 border-b border-gray-200">Performance Chart</h3>
            <div className="p-4">
              <InvestmentPerformanceChart data={mockGraphData} />
            </div>
          </div>
          <div className="bg-white border border-gray-200 rounded-sm shadow-sm">
            <h3 className="text-md font-semibold mb-2 text-tertiary bg-gray-50 p-3 border-b border-gray-200">Investment Projections</h3>
            <div className="p-4">
              <InvestmentProjections
                annualReturn={annualReturn}
                breakEven={breakEven}
                profitAfter10Years={profitAfter10Years}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="hidden my-8 bg-white p-4 sm:p-6">
        <h2 className="text-center text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-tertiary">Your Solar Impact</h2>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
          <div className="bg-green-100 p-4 rounded-sm text-center">
            <div className="flex items-center justify-center mb-2 sm:mb-4 h-16">
              <FaLeaf className="text-green-600 w-10 h-10" />
            </div>
            <h2 className="text-lg sm:text-xl font-semibold text-green-600">CO2 Reduced</h2>
            <p className="text-2xl sm:text-4xl font-bold text-green-600">{solarStats.co2Reduced} kg</p>
            <p className="text-sm text-gray-600 mt-2">Equivalent to planting {solarStats.treesPlanted} trees</p>
          </div>
          <div className="bg-blue-100 p-4 rounded-sm text-center">
            <div className="flex items-center justify-center mb-2 sm:mb-4 h-16">
              <FaTree className="text-blue-600 w-10 h-10" />
            </div>
            <h2 className="text-lg sm:text-xl font-semibold text-blue-600">Trees Planted</h2>
            <p className="text-2xl sm:text-4xl font-bold text-blue-600">{solarStats.treesPlanted}</p>
            <p className="text-sm text-gray-600 mt-2">Creating a mini-forest!</p>
          </div>
          <div className="bg-yellow-100 p-4 rounded-sm text-center">
            <div className="flex items-center justify-center mb-2 sm:mb-4 h-16">
              <FaHome className="text-yellow-600 w-10 h-10" />
            </div>
            <h2 className="text-lg sm:text-xl font-semibold text-yellow-600">Homes Powered</h2>
            <p className="text-2xl sm:text-4xl font-bold text-yellow-600">{solarStats.householdsPowered}</p>
            <p className="text-sm text-gray-600 mt-2">That's a whole neighborhood!</p>
          </div>
          <div className="bg-orange-100 p-4 rounded-sm text-center">
            <div className="flex items-center justify-center mb-2 sm:mb-4 h-16">
              <FaGasPump className="text-orange-600 w-10 h-10" />
            </div>
            <h2 className="text-lg sm:text-xl font-semibold text-orange-600">Fuel Saved</h2>
            <p className="text-2xl sm:text-4xl font-bold text-orange-600">{solarStats.fuelSaved} gal</p>
            <p className="text-sm text-gray-600 mt-2">Enough for {Math.round(solarStats.fuelSaved * 25)} miles of driving</p>
          </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-4">
          <div className="bg-purple-100 p-4 rounded-sm text-center">
            <div className="flex items-center justify-center mb-2 sm:mb-4 h-16">
              <FaCar className="text-purple-600 w-10 h-10" />
            </div>
            <h2 className="text-lg sm:text-xl font-semibold text-purple-600">Cars Off Road</h2>
            <p className="text-2xl sm:text-4xl font-bold text-purple-600">{solarStats.carsOffRoad}</p>
            <p className="text-sm text-gray-600 mt-2">Equivalent to removing {solarStats.carsOffRoad} cars for a year</p>
          </div>
          <div className="bg-indigo-100 p-4 rounded-sm text-center">
            <div className="flex items-center justify-center mb-2 sm:mb-4 h-16">
              <FaWater className="text-indigo-600 w-10 h-10" />
            </div>
            <h2 className="text-lg sm:text-xl font-semibold text-indigo-600">Water Saved</h2>
            <p className="text-2xl sm:text-4xl font-bold text-indigo-600">{solarStats.waterSaved} L</p>
            <p className="text-sm text-gray-600 mt-2">Enough to fill {Math.round(solarStats.waterSaved / 200)} bathtubs</p>
          </div>
          <div className="bg-pink-100 p-4 rounded-sm text-center">
            <div className="flex items-center justify-center mb-2 sm:mb-4 h-16">
              <FaLightbulb className="text-pink-600 w-10 h-10" />
            </div>
            <h2 className="text-lg sm:text-xl font-semibold text-pink-600">LED Bulbs Equivalent</h2>
            <p className="text-2xl sm:text-4xl font-bold text-pink-600">{solarStats.lightbulbsReplaced}</p>
            <p className="text-sm text-gray-600 mt-2">Energy saved equals {solarStats.lightbulbsReplaced} LED bulbs</p>
          </div>
          <div className="bg-teal-100 p-4 rounded-sm text-center">
            <div className="flex items-center justify-center mb-2 sm:mb-4 h-16">
              <FaLeaf className="text-teal-600 w-10 h-10" />
            </div>
            <h2 className="text-lg sm:text-xl font-semibold text-teal-600">Carbon Footprint Reduced</h2>
            <p className="text-2xl sm:text-4xl font-bold text-teal-600">{solarStats.carbonFootprintReduced} tons</p>
            <p className="text-sm text-gray-600 mt-2">Equivalent to {Math.round(solarStats.carbonFootprintReduced * 2)} round-trip flights</p>
          </div>
        </div>
      </div>
      {invoice && <InvoiceDisplay invoice={invoice} onClose={handleCloseInvoice} />}
    </div>
  );
}

export default InvestorDashboard;