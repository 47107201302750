import React, { useState, useEffect } from 'react';

function PaymentManagement() {
  const [bankDetails, setBankDetails] = useState({
    accountName: '',
    accountNumber: '',
    bankName: '',
    ifscCode: '',
  });
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [showBankDetails, setShowBankDetails] = useState(true);
  const [bankDetailsSaved, setBankDetailsSaved] = useState(false);
  const [savedBankDetails, setSavedBankDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    // Simulating API call to fetch saved bank details
    const fetchSavedBankDetails = async () => {
      // Replace this with actual API call
      const mockSavedDetails = {
        accountName: 'John Doe',
        accountNumber: '1234567890',
        bankName: 'Example Bank',
        ifscCode: 'EXMP0001234',
      };
      setSavedBankDetails(mockSavedDetails);
      setBankDetails(mockSavedDetails);
    };

    fetchSavedBankDetails();
    // Simulating API call to fetch payment history
    const fetchPaymentHistory = async () => {
      // Replace this with actual API call
      const mockPaymentHistory = [
        { id: 'TRX001', date: '2023-05-01', amount: 500, status: 'Completed' },
        { id: 'TRX002', date: '2023-06-01', amount: 550, status: 'Completed' },
        { id: 'TRX003', date: '2023-07-01', amount: 600, status: 'Pending' },
        { id: 'TRX004', date: '2023-08-01', amount: 575, status: 'Completed' },
        { id: 'TRX005', date: '2023-09-01', amount: 625, status: 'Pending' },
      ];
      setPaymentHistory(mockPaymentHistory);
    };

    fetchPaymentHistory();
  }, []);

  const handleBankDetailsChange = (e) => {
    setBankDetails({ ...bankDetails, [e.target.name]: e.target.value });
  };

  const handleBankDetailsSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the data to your backend
    console.log('Submitted bank details:', bankDetails);
    setSavedBankDetails(bankDetails);
    setBankDetailsSaved(true);
    setIsEditing(false);
    setTimeout(() => setBankDetailsSaved(false), 3000);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setBankDetails(savedBankDetails);
  };

  return (
    <div className="container max-w-7xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6 text-tertiary">Payment Management</h1>
      <div className="my-4 ">
        <button
          className={`mr-2 px-4 py-2 rounded-sm ${showBankDetails ? 'bg-secondary text-white' : 'bg-tertiary text-white'}`}
          onClick={() => setShowBankDetails(true)}
        >
          Bank Details
        </button>
        <button
          className={`px-4 py-2 rounded-sm ${!showBankDetails ? 'bg-secondary text-white' : 'bg-tertiary text-white'}`}
          onClick={() => setShowBankDetails(false)}
        >
          Payment History
        </button>
      </div>
      {showBankDetails ? (
        <div>
          <h2 className="text-xl font-semibold my-8 text-tertiary">Bank Details</h2>
          {bankDetailsSaved && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-sm relative mb-4" role="alert">
              <strong className="font-bold">Success!</strong>
              <span className="block sm:inline"> Bank details updated successfully.</span>
            </div>
          )}
          {savedBankDetails && !isEditing ? (
            <div className="bg-primary rounded-sm shadow-md px-8 py-6 mb-6">
              <h3 className="text-xl font-semibold mb-4 text-tertiary">Your Bank Details</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                <div className="bg-gray-50 p-3 rounded-sm">
                  <p className="text-sm text-gray-600">Account Name</p>
                  <p className="font-medium text-tertiary">{savedBankDetails.accountName}</p>
                </div>
                <div className="bg-gray-50 p-3 rounded-sm">
                  <p className="text-sm text-gray-600">Account Number</p>
                  <p className="font-medium text-tertiary">{savedBankDetails.accountNumber}</p>
                </div>
                <div className="bg-gray-50 p-3 rounded-sm">
                  <p className="text-sm text-gray-600">Bank Name</p>
                  <p className="font-medium text-tertiary">{savedBankDetails.bankName}</p>
                </div>
                <div className="bg-gray-50 p-3 rounded-sm">
                  <p className="text-sm text-gray-600">IFSC Code</p>
                  <p className="font-medium text-tertiary">{savedBankDetails.ifscCode}</p>
                </div>
              </div>
              <button
                onClick={handleEditClick}
                className="w-full md:w-auto bg-secondary hover:bg-opacity-90 text-white font-medium py-2 px-4 rounded-sm transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50"
              >
                Edit Bank Details
              </button>
            </div>
          ) : (
            <form onSubmit={handleBankDetailsSubmit} className="bg-primary shadow-md rounded-sm px-8 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label className="block text-tertiary text-sm font-bold mb-2" htmlFor="accountName">
                  Account Name
                </label>
                <input
                  className="shadow appearance-none border rounded-sm w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="accountName"
                  type="text"
                  name="accountName"
                  value={bankDetails.accountName}
                  onChange={handleBankDetailsChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-tertiary text-sm font-bold mb-2" htmlFor="accountNumber">
                  Account Number
                </label>
                <input
                  className="shadow appearance-none border rounded-sm w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="accountNumber"
                  type="text"
                  name="accountNumber"
                  value={bankDetails.accountNumber}
                  onChange={handleBankDetailsChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-tertiary text-sm font-bold mb-2" htmlFor="bankName">
                  Bank Name
                </label>
                <input
                  className="shadow appearance-none border rounded-sm w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="bankName"
                  type="text"
                  name="bankName"
                  value={bankDetails.bankName}
                  onChange={handleBankDetailsChange}
                  required
                />
              </div>
              <div className="mb-6">
                <label className="block text-tertiary text-sm font-bold mb-2" htmlFor="ifscCode">
                  IFSC Code
                </label>
                <input
                  className="shadow appearance-none border rounded-sm w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="ifscCode"
                  type="text"
                  name="ifscCode"
                  value={bankDetails.ifscCode}
                  onChange={handleBankDetailsChange}
                  required
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="bg-secondary hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded-sm focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Save
                </button>
                {isEditing && (
                  <button
                    onClick={() => setIsEditing(false)}
                    className="bg-tertiary hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded-sm focus:outline-none focus:shadow-outline"
                    type="button"
                  >
                    Cancel
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      ) : (
        <div>
          <h2 className="text-xl font-semibold mb-4 text-tertiary my-8">Payment History</h2>
          
          {/* Table view for medium screens and up */}
          <div className="hidden sm:block overflow-x-auto bg-white shadow-md rounded-sm">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-primary">
                <tr>
                  <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                  <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                  <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {paymentHistory.map((payment) => (
                  <tr key={payment.id}>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{payment.date}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">${payment.amount.toFixed(2)}</td>
                    <td className="px-4 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-sm-full ${
                        payment.status === 'Completed' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {payment.status}
                      </span>
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{payment.id}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          {/* Card view for small screens */}
          <div className="sm:hidden mt-4">
            {paymentHistory.map((payment) => (
              <div key={payment.id} className="bg-white shadow rounded-sm p-4 mb-4">
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-medium text-gray-900">{payment.date}</span>
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-sm-full ${
                    payment.status === 'Completed' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                  }`}>
                    {payment.status}
                  </span>
                </div>
                <div className="text-sm text-gray-500">Amount: ${payment.amount.toFixed(2)}</div>
                <div className="text-sm text-gray-500">ID: {payment.id}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default PaymentManagement;