import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { EnvelopeIcon, CloudIcon, BoltIcon, CheckBadgeIcon } from '@heroicons/react/24/outline';
import CapacitySelector from '../components/CapacitySelector';
import { LightBulbIcon, ChartBarIcon, GlobeAsiaAustraliaIcon, CurrencyDollarIcon, ArrowPathIcon } from '@heroicons/react/24/outline';

function Home() {
  const { t, i18n } = useTranslation();
  const [scrollY, setScrollY] = useState(0);
  const [capacity, setCapacity] = useState(0);

  const location = useLocation();

  useEffect(() => {
    const lang = location.pathname.split('/')[1];
    if (lang && (lang === 'en' || lang === 'th')) {
      i18n.changeLanguage(lang);
    }
  }, [location, i18n]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const backgroundImageUrl = '/img/hero-image.jpg';

  const calculateExampleRoiData = () => {
    const initialInvestment = 15000; // Fixed at 15,000 THB
    const annualRoi = 0.08; // 8.0% annual return

    return Array.from({ length: 16 }, (_, i) => ({
      year: i,
      roi: Math.round((Math.pow(1 + annualRoi, i) - 1) * 100), // Cumulative ROI percentage
      cumulativeReturns: Math.round(initialInvestment * Math.pow(1 + annualRoi, i)), // Total value including initial investment
      annualReturns: Math.round(initialInvestment * annualRoi * i), // Cumulative returns only
    }));
  };


  return (
    <div className="landing-page">

      <section className="hero relative bg-cover bg-center h-screen flex items-center justify-center overflow-hidden">
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="parallax-bg absolute inset-0 bg-cover bg-center" style={{
          backgroundImage: `url(${backgroundImageUrl})`,
          transform: `translateY(${scrollY * 0.5}px)`
        }}></div>
        <div className="container mx-auto px-4 z-10 text-center">
          <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6 text-white animate-fade-in-up">
            {t('hero.investBrighter')}
          </h1>
          <p className="text-xl sm:text-2xl mb-10 text-white animate-fade-in-up animation-delay-300 max-w-2xl mx-auto">
            {t('hero.crowdfundSolar')}
          </p>

          <div className="mt-16 flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6 px-4 sm:px-0">
            <a href="#buy-now" className="bg-accent text-white text-lg font-medium py-2 px-6 rounded-sm text-center hover:bg-opacity-90 transition duration-300 animate-fade-in-up animation-delay-600">
              {t('hero.startInvesting')}
            </a>
            <a href="#how-it-works" className="bg-tertiary text-white text-lg font-medium py-2 px-6 rounded-sm text-center hover:bg-opacity-90 transition duration-300 animate-fade-in-up animation-delay-600">
              {t('hero.learnHow')}
            </a>
          </div>
        </div>
      </section>

      <section id='buy-now' className="py-12 mt-8 bg-white">
        <div className="container mx-auto px-4">
          <div className="max-w-full mx-auto text-center">
            <h2 className="mb-6 text-2xl md:text-3xl font-semibold text-tertiary">
              {t('welcome.title')}
            </h2>
            <p className="text-base mb-6 text-gray-600 leading-relaxed">
              {t('welcome.description')}
            </p>
            <div id="capacity-selector" className="mt-8">
              <CapacitySelector capacity={capacity} setCapacity={setCapacity} />
            </div>
          </div>
        </div>
      </section>

      <section id="how-it-works" className="mt-8 py-12 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-xl md:text-2xl font-semibold text-center mb-8 text-tertiary">
            {t('howItWorks.title')}
          </h2>
          <div className="max-w-5xl mx-auto relative">
            {/* Vertical line for icons */}
            <div className="absolute left-1/2 top-0 bottom-0 w-0.5 bg-primary"></div>

            {[
              {
                icon: <LightBulbIcon className="h-6 w-6 text-white" />,
                title: t('howItWorks.steps.choose.title'),
                description: t('howItWorks.steps.choose.description')
              },
              {
                icon: <ChartBarIcon className="h-6 w-6 text-white" />,
                title: t('howItWorks.steps.track.title'),
                description: t('howItWorks.steps.track.description')
              },
              {
                icon: <GlobeAsiaAustraliaIcon className="h-6 w-6 text-white" />,
                title: t('howItWorks.steps.impact.title'),
                description: t('howItWorks.steps.impact.description')
              },
              {
                icon: <CurrencyDollarIcon className="h-6 w-6 text-white" />,
                title: t('howItWorks.steps.receive.title'),
                description: t('howItWorks.steps.receive.description')
              },
              {
                icon: <ArrowPathIcon className="h-6 w-6 text-white" />,
                title: t('howItWorks.steps.reinvest.title'),
                description: t('howItWorks.steps.reinvest.description')
              }
            ].map((step, index) => (
              <div key={index} className="flex items-center mb-6 relative">
                {/* Icon */}
                <div className="absolute left-1/2 transform -translate-x-1/2 z-10">
                  <div className="bg-secondary rounded-full p-1.5">
                    {step.icon}
                  </div>
                </div>

                {/* Content */}
                <div className={`w-5/12 ${index % 2 === 0 ? 'mr-auto pr-6 text-right' : 'ml-auto pl-6 text-left'}`}>
                  <h3 className="text-base font-medium mb-1 text-tertiary">{step.title}</h3>
                  <p className="text-sm text-gray-600">{step.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="investment-opportunities" className="py-20 bg-tertiary">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl md:text-3xl font-semibold text-center mb-8 text-white">
            {t('investmentPaths.title')}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: t('investmentPaths.paths.largescale.title'),
                description: t('investmentPaths.paths.largescale.description'),
                features: [
                  t('investmentPaths.paths.largescale.features.0'),
                  t('investmentPaths.paths.largescale.features.1'),
                  t('investmentPaths.paths.largescale.features.2')
                ],
              },
              {
                title: t('investmentPaths.paths.homes.title'),
                description: t('investmentPaths.paths.homes.description'),
                features: [
                  t('investmentPaths.paths.homes.features.0'),
                  t('investmentPaths.paths.homes.features.1'),
                  t('investmentPaths.paths.homes.features.2')
                ],
              },
              {
                title: t('investmentPaths.paths.industrial.title'),
                description: t('investmentPaths.paths.industrial.description'),
                features: [
                  t('investmentPaths.paths.industrial.features.0'),
                  t('investmentPaths.paths.industrial.features.1'),
                  t('investmentPaths.paths.industrial.features.2')
                ],
              }
            ].map((path, index) => (
              <div key={index} className="bg-white rounded-sm shadow-md overflow-hidden flex flex-col h-full transform transition duration-300 hover:scale-105">
                <div className="p-6 flex-grow">
                  <div className="text-4xl mb-4">{path.icon}</div>
                  <h3 className="text-lg font-medium mb-4 text-tertiary">{path.title}</h3>
                  <p className="text-sm text-gray-600 mb-4">{path.description}</p>
                  <ul className="text-sm text-gray-600 mb-4 list-disc list-inside">
                    {path.features.map((feature, i) => (
                      <li key={i}>{feature}</li>
                    ))}
                  </ul>
                </div>
                <div className="bg-primary p-4 mt-auto">
                  <a href="#contact" className="block text-center text-accent text-sm font-medium py-2 px-4 rounded-sm hover:bg-opacity-90 transition duration-300">
                    {t('contact')}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="financial-gains" className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl md:text-3xl font-semibold text-center mb-12 text-tertiary">
            {t('returnsWithHelio')}
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            <div className="flex flex-col h-full">
              <div className="flex-grow relative rounded-sm-lg overflow-hidden shadow-md">
                <img
                  src="/img/solar-home.jpg"
                  alt="Solar Panels"
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="flex flex-col h-full">
              <div className="bg-primary rounded-sm-sm shadow-md p-4 mb-4 flex-grow">
                <h3 className="text-lg font-medium mb-4 text-tertiary text-center">{t('returns.investmentGrowth')}</h3>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={calculateExampleRoiData()}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="year" />
                    <YAxis yAxisId="left" unit="%" />
                    <YAxis yAxisId="right" orientation="right" unit=" THB" />
                    <Tooltip 
                      contentStyle={{
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        border: 'none',
                        borderRadius: '4px',
                        padding: '8px',
                        fontSize: '12px'
                      }}
                      itemStyle={{ padding: '2px 0' }}
                      labelStyle={{ marginBottom: '4px' }}
                      formatter={(value, name) => {
                        if (name === "ROI") return [`${value}%`, 'ROI'];
                        if (name === "Total Investment Value") return [`${value.toLocaleString()} THB`, 'Total Value'];
                        if (name === "Cumulative Returns") return [`${value.toLocaleString()} THB`, 'Returns'];
                        return value;
                      }}
                      labelFormatter={(label) => `Year ${label}`}
                    />
                    <Legend />
                    <Line yAxisId="left" type="monotone" dataKey="roi" stroke="#eab308" name="ROI" />
                    <Line yAxisId="right" type="monotone" dataKey="cumulativeReturns" stroke="#1e3c63" name="Total Investment Value" />
                    <Line yAxisId="right" type="monotone" dataKey="annualReturns" stroke="#82BF53" name="Cumulative Returns" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <p className="text-center text-sm text-tertiary">
                {t('watchInvestment')}
              </p>
              <div className="bg-white rounded-sm-lg shadow-md p-6">
                <h3 className="text-lg font-medium mb-4 text-tertiary text-center">{t('returns.exampleDetails')}</h3>
                <ul className="space-y-2">
                  <li className="flex justify-between">
                    <span className="font-medium">{t('returns.totalInvestment')}</span>
                    <span>15,000 THB</span>
                  </li>
                  <li className="flex justify-between">
                    <span className="font-medium">{t('returns.annualReturns')}</span>
                    <span>8.0% {i18n.language === 'th' ? 'ต่อปี' : 'per year'}</span>
                  </li>
                  <li className="flex justify-between">
                    <span className="font-medium">{t('returns.totalReturns')}</span>
                    <span>{new Intl.NumberFormat(i18n.language).format(Math.round(15000 * (Math.pow(1.08, 15) - 1)))} THB</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="sustainability-impact" className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl md:text-3xl font-semibold text-center mb-12 text-tertiary">
            {t('impact.title')}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            <div className="text-center">
              <div className="bg-primary text-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-3">
                <CloudIcon className="h-8 w-8 text-accent" />
              </div>
              <h3 className="text-lg font-medium mb-2 text-tertiary">{t('impact.carbonSavings')}</h3>
              <p className="text-xl font-semibold text-secondary">
                {new Intl.NumberFormat(i18n.language).format(1500)}
                {i18n.language === 'th' ? ' ตัน' : ' tons'}
              </p>
              <p className="text-sm text-gray-600">{t('impact.co2Avoided')}</p>
            </div>
            <div className="text-center">
              <div className="bg-primary text-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-3">
                <BoltIcon className="h-8 w-8 text-accent" />
              </div>
              <h3 className="text-lg font-medium mb-2 text-tertiary">{t('impact.renewableEnergy')}</h3>
              <p className="text-xl font-semibold text-secondary">
                {new Intl.NumberFormat(i18n.language).format(2.5)} GWh
              </p>
              <p className="text-sm text-gray-600">{t('impact.cleanEnergy')}</p>
            </div>
            <div className="text-center">
              <div className="bg-primary text-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-3">
                <CheckBadgeIcon className="h-8 w-8 text-accent" />
              </div>
              <h3 className="text-lg font-medium mb-2 text-tertiary">{t('impact.greenCertifications')}</h3>
              <p className="text-xl font-semibold text-secondary">
                {new Intl.NumberFormat(i18n.language).format(50)}+
              </p>
              <p className="text-sm text-gray-600">{t('impact.certifiedGreen')}</p>
            </div>
          </div>
        </div>
      </section>

      <footer className="bg-tertiary text-white pt-12">
        <div id="contact" className="container mx-auto px-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-12 gap-8 mb-8">
            {/* Logo and Description */}
            <div className="col-span-1 sm:col-span-2 md:col-span-5 text-center sm:text-left">
              <img
                src="/img/heliogains-white-color.svg"
                alt="Helio Gains Logo"
                className="h-8 sm:h-10 w-auto mb-4 mx-auto sm:mx-0"
              />
              <p className="text-sm text-gray-300">
                {t('footer.empoweringSustainable')}
              </p>
              <div className="mt-4 flex flex-row gap-4 justify-center sm:justify-start">
                <img src="/img/sdg7.png" alt="SDG7" className="h-10 sm:h-12 w-auto" />
                <img src="/img/sdg13.png" alt="SDG13" className="h-10 sm:h-12 w-auto" />
              </div>
            </div>

            {/* Quick Links */}
            <div className="col-span-1 sm:col-span-1 md:col-span-4 text-center sm:text-left">
              <h3 className="text-lg font-semibold mb-4">{t('footer.quickLinks')}</h3>
              <ul className="space-y-2">
                <li><a href="#how-it-works" className="text-gray-300 hover:text-accent transition-colors">{t('common.navigation.howItWorks')}</a></li>
                <li><a href="#investment-opportunities" className="text-gray-300 hover:text-accent transition-colors">{t('common.navigation.invest')}</a></li>
                <li><a href="#sustainability-impact" className="text-gray-300 hover:text-accent transition-colors">{t('common.navigation.impact')}</a></li>
                <li><Link to="/login" className="text-gray-300 hover:text-accent transition-colors">{t('common.navigation.login')}</Link></li>
                <li><Link to="/terms" className="text-gray-300 hover:text-accent transition-colors">{t('common.navigation.terms')}</Link></li>
                <li><Link to="/privacy" className="text-gray-300 hover:text-accent transition-colors">{t('common.navigation.privacy')}</Link></li>
              </ul>
            </div>

            {/* Contact */}
            <div className="col-span-1 sm:col-span-1 md:col-span-3 text-center sm:text-left">
              <h3 className="text-lg font-semibold mb-4">{t('footer.contact')}</h3>
              <div className="flex items-center text-gray-300 justify-center sm:justify-start">
                <EnvelopeIcon className="h-5 w-5 mr-2" />
                <a href="mailto:info@heliogains.com" className="hover:text-accent transition-colors">info@heliogains.com</a>
              </div>
            </div>
          </div>

          <div className="border-t border-gray-700 py-4 mt-4 flex flex-col sm:flex-row justify-between items-center text-xs space-y-2 sm:space-y-0 text-center">
            <p>{t('footer.copyright')}</p>
            <p>{t('footer.version')} 1.0.6</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
