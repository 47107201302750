import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'animate.css';

function OutOfCapacityModal({ isOpen, onClose }) {
  const { t } = useTranslation();
  const [animate, setAnimate] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setAnimate(true);
        setIsClosing(false);
      }, 100); // Small delay to ensure the modal is visible before animations start
    } else {
      setAnimate(false);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 500);
  };

  if (!isOpen && !isClosing) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
      <div className={`bg-primary p-4 sm:p-8 rounded-md shadow-xl w-full max-w-lg animate__animated ${isClosing ? 'animate__zoomOut' : 'animate__zoomIn'} animate__slow`}>
        <h2 className={`text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-tertiary text-center ${animate ? 'animate-fade-in' : ''}`}>
          {t('modal.outOfCapacity.title')}
        </h2>

        <div className="space-y-3 sm:space-y-4 mb-4 sm:mb-6 text-sm sm:text-base">
          <p className="text-center">
            {t('modal.outOfCapacity.verifyEmail')}
          </p>
          <p className="text-center">
            {t('modal.outOfCapacity.limitedOpportunity')}
          </p>
          <p className="text-center text-tertiary font-medium">
            🌞
          </p>
        </div>
        <button 
          onClick={handleClose}
          className="w-full bg-accent text-white font-semibold py-2 px-4 rounded hover:bg-opacity-90 transition duration-300"
        >
          {t('modal.outOfCapacity.close')}
        </button>
      </div>
    </div>
  );
}

export default OutOfCapacityModal;
