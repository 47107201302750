export const trackClick = async (linkName) => {
  try {
    const response = await fetch(`/clicks/${linkName}`, {
      method: 'POST',
    });
    return response.ok;
  } catch (error) {
    // Silently fail - don't affect user experience
    console.log('Analytics tracking failed:', error);
    return false;
  }
};
