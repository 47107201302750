import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function NotFound() {
  const { t } = useTranslation();
  return (
    <div className="min-h-screen flex flex-col bg-cover bg-center" style={{ backgroundImage: "url('/img/solar-panels.jpg')" }}>
      <div className="flex-grow flex items-center justify-center px-4 py-8">
        <div className="bg-primary bg-opacity-10 p-12 rounded-sm shadow-lg w-full max-w-md text-center">
          <h1 className="text-4xl font-semibold mb-4 text-tertiary">{t('common.error.404')}</h1>
          <h2 className="text-2xl font-semibold mb-4 text-white">{t('common.error.pageNotFound')}</h2>
          <p className="text-white mb-6">{t('common.error.pageNotFoundMessage')}</p>
          <Link 
            to="/" 
            className="bg-accent text-white font-semibold py-2 px-4 rounded-sm hover:bg-opacity-90 transition duration-300"
          >
            {t('common.navigation.backHome')}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;

