import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const MonthlyEnergy = ({ data }) => {
  return (
    <div>
        <h2 className="text-xl font-semibold mb-4 text-tertiary">Monthly Energy Generation</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="energy" stroke="#8884d8" name="Energy (kWh)" />
            </LineChart>
          </ResponsiveContainer>
    </div>
  )
}

export default MonthlyEnergy