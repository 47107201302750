import React from 'react';

function InvoiceDisplay({ invoice, onClose }) {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="bg-white p-8 rounded-sm shadow-lg max-w-2xl w-full">
        <h2 className="text-2xl font-bold mb-4 text-tertiary">Invoice</h2>
        <div className="mb-4">
          <h3 className="font-semibold text-lg mb-2">Seller</h3>
          <p>{invoice.seller.name}</p>
          <p>{invoice.seller.address}</p>
        </div>
        <div className="mb-4">
          <h3 className="font-semibold text-lg mb-2">Buyer</h3>
          <p>{invoice.buyer.name}</p>
          <p>{invoice.buyer.address}</p>
        </div>
        <div className="mb-4">
          <h3 className="font-semibold text-lg mb-2">Details</h3>
          <p>Total Capacity: {invoice.totalCapacity} kW</p>
          <p>Total Amount: {invoice.totalAmount.toLocaleString()} THB</p>
          <p>Invoice Date: {invoice.invoiceDate}</p>
          <p>Payment Due: {invoice.invoiceTermDate}</p>
        </div>
        <button 
          onClick={onClose}
          className="bg-tertiary text-white px-4 py-2 rounded-sm hover:bg-opacity-80 transition duration-300"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default InvoiceDisplay;
