import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import SimpleHeader from './SimpleHeader';

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userType, setUserType] = useState('host');
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords don't match");
      return;
    }

    try {
      const existingUsers = JSON.parse(localStorage.getItem('users') || '[]');
      const newUser = { email, password, userType };
      localStorage.setItem('users', JSON.stringify([...existingUsers, newUser]));

      setWelcomeMessage(`Welcome to Helio Gains, ${email}! Please log in to access your dashboard.`);
      
      // Reset form after signup
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      
      // Navigate to the login page after a delay
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      console.error('Sign-up error:', error);
      alert('Sign-up failed. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-cover bg-center" style={{ backgroundImage: "url('/img/solar-panels.jpg')" }}>
      <SimpleHeader />
      <div className="flex-grow flex items-center justify-center px-4 py-8">
        <div className="bg-primary bg-opacity-10 p-8 sm:p-8 rounded-sm shadow-md w-full max-w-md">
          <h1 className={`text-xl sm:text-2xl font-bold mb-4 text-center ${userType === 'host' ? 'text-secondary' : 'text-tertiary'}`}>Sign Up for HelioGains</h1>
          {welcomeMessage && (
            <div className={`border border-green-400 text-white px-4 py-3 rounded-sm relative mb-4 text-sm sm:text-base ${userType === 'host' ? 'bg-secondary' : 'bg-tertiary'}`}>
              {welcomeMessage}
            </div>  
          )}
          <div className="flex mb-4">
            <button
              className={`flex-1 py-2 rounded-sm-tl-md rounded-sm-bl-md text-sm sm:text-base bg-secondary text-white ${userType === 'host' ? '' : 'opacity-70 hover:opacity-90'}`}
              onClick={() => setUserType('host')}
            >
              Host
            </button>
            <button
              className={`flex-1 py-2 rounded-sm-tr-md rounded-sm-br-md text-sm sm:text-base bg-tertiary text-white ${userType === 'investor' ? '' : 'opacity-60 hover:opacity-90'}`}
              onClick={() => setUserType('investor')}
            >
              Investor
            </button>
          </div>
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="email"
              placeholder="Email"
              className="w-full p-2 border rounded-sm bg-white bg-opacity-80 text-gray-800 text-sm sm:text-base"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              className="w-full p-2 border rounded-sm bg-white bg-opacity-80 text-gray-800 text-sm sm:text-base"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Confirm Password"
              className="w-full p-2 border rounded-sm bg-white bg-opacity-80 text-gray-800 text-sm sm:text-base"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <button className={`w-full text-white py-2 rounded-sm hover:bg-opacity-90 text-sm sm:text-base ${userType === 'host' ? 'bg-secondary' : 'bg-tertiary'}`} type="submit">
              Sign Up
            </button>
          </form>
          <p className="mt-4 text-center text-white text-sm sm:text-base">
            Already have an account? <Link to="/login" className="text-accent hover:underline">Login</Link>
          </p>
          <p className="mt-4 text-center text-xs sm:text-sm text-white">
            By signing up, you agree to our{' '}
            <Link to="/terms" className={`hover:underline hover:opacity-70 ${userType === 'host' ? 'text-secondary' : 'text-tertiary'}`}>
              Terms and Conditions
            </Link>{' '}
            and{' '}
            <Link to="/privacy" className={`hover:underline hover:opacity-70 ${userType === 'host' ? 'text-secondary' : 'text-tertiary'}`}>
              Privacy Policy
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
