import React, { useState } from 'react';

function MeterReading() {
  const [reading, setReading] = useState('');
  const [date, setDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  });
  const [submitted, setSubmitted] = useState(false);

  const [history, setHistory] = useState([
    { id: 1, reading: 1000, date: '23-05-01', consumption: 0, unitPrice: 3.23, total: 0 },
    { id: 2, reading: 1200, date: '23-06-01', consumption: 200, unitPrice: 3.23, total: 0 },
    { id: 3, reading: 1400, date: '23-07-01', consumption: 200, unitPrice: 3.23, total: 0 },
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newReading = Number(reading);
    const lastReading = history[0]?.reading || 0;
    const consumption = newReading - lastReading;

    setHistory([
      { 
        id: history.length + 1, 
        reading: newReading, 
        date, 
        consumption,
        unitPrice: 3.23
      },
      ...history
    ]);

    console.log('Submitted reading:', reading, 'for date:', date);
    setSubmitted(true);
    setReading('');
    setDate(() => {
      const today = new Date();
      return today.toISOString().split('T')[0];
    });
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-tertiary text-base sm:text-base font-bold mb-6">Submit your meter reading</h1>
      {submitted && (
        <div className="bg-secondary border border-green-400 text-white px-4 py-3 rounded-sm relative mb-4" role="alert">
          <strong className="font-bold">Success!</strong>
          <span className="block sm:inline"> Meter reading submitted successfully.</span>
        </div>
      )}
      <form onSubmit={handleSubmit} className="mb-8">
        <div className="flex flex-col sm:flex-row sm:space-x-4">
          <div className="mb-4 sm:mb-0 sm:w-1/2">
            <label className="block text-tertiary text-sm font-bold mb-2" htmlFor="reading">
              Current Meter Reading (kWh)
            </label>
            <input
              className="shadow appearance-none border rounded-sm w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="reading"
              type="number"
              placeholder="Enter meter reading"
              value={reading}
              onChange={(e) => setReading(e.target.value)}
              required
            />
          </div>
          <div className="mb-4 sm:mb-0 sm:w-1/2">
            <label className="block text-tertiary text-sm font-bold mb-2" htmlFor="date">
              Date
            </label>
            <input
              className="shadow appearance-none border rounded-sm w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="flex items-center justify-end mt-6">
          <button
            className="bg-secondary hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded-sm focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
       <h2 className="text-lg font-semibold mb-4 text-tertiary">Reading History</h2>
       <div className="overflow-x-auto rounded-sm shadow-md">
            <table className="w-full text-left text-tertiary">
              <thead>
                <tr className="bg-primary">
                  <th className="py-2 px-4 font-semibold">Date</th>
                  <th className="py-2 px-4 font-semibold">Reading (kWh)</th>
                  <th className="py-2 px-4 font-semibold">Consumption (kWh)</th>
                  <th className="py-2 px-4 font-semibold">Unit Price (THB/kWh)</th>
                  <th className="py-2 px-4 font-semibold">Total (THB)</th>
                </tr>
              </thead>
              <tbody>
                {history.map((item) => (
                  <tr key={item.id} className="border-t border-gray-200">
                    <td className="py-2 px-4">{item.date}</td>
                    <td className="py-2 px-4">{item.reading}</td>
                    <td className="py-2 px-4">{item.consumption}</td>
                    <td className="py-2 px-4">{item.unitPrice.toFixed(2)}</td>
                    <td className="py-2 px-4">{(item.consumption * item.unitPrice).toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
    </div>
  );
}

export default MeterReading;