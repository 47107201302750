import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

function MeterReadingManagement() {
  const [unvalidatedReadings, setUnvalidatedReadings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch unvalidated readings from API
    fetchUnvalidatedReadings();
  }, []);

  const fetchUnvalidatedReadings = async () => {
    // Simulated API call
    const mockReadings = [
      { id: 1, hostId: 'host1', reading: 1500, date: '2023-08-01', status: 'unvalidated' },
      { id: 2, hostId: 'host2', reading: 2000, date: '2023-08-02', status: 'unvalidated' },
      { id: 3, hostId: 'host3', reading: 1800, date: '2023-08-03', status: 'unvalidated' },
    ];
    setUnvalidatedReadings(mockReadings);
  };

  const handleValidate = async (readingId) => {
    setIsLoading(true);
    try {
      // Simulated API call to validate reading
      const response = await fetch(`/api/meter-readings/${readingId}/validate`, {
        method: 'POST',
      });
      
      if (!response.ok) {
        throw new Error('Failed to validate reading');
      }

      const updatedReading = await response.json();

      // Update the reading status in the state
      setUnvalidatedReadings(prevReadings =>
        prevReadings.map(reading =>
          reading.id === readingId ? { ...reading, status: 'validated' } : reading
        )
      );

      // Adjust revenue calculation
      // This is a simplified example. In a real app, you might want to fetch the updated revenue from the backend
      const revenueAdjustment = updatedReading.reading * 0.1; // Assuming $0.1 per kWh
      
      toast.success(`Reading validated successfully. Revenue adjusted by $${revenueAdjustment.toFixed(2)}`);
    } catch (error) {
      console.error('Error validating reading:', error);
      toast.error('Failed to validate reading. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-6 sm:py-8">
      <h1 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-tertiary">Meter Reading Management</h1>
      {isLoading && <div className="text-center">Loading...</div>}
      <div className="bg-white shadow-md rounded-sm overflow-hidden">
        {/* Table for larger screens */}
        <div className="hidden sm:block">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Host ID</th>
                <th className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Reading</th>
                <th className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {unvalidatedReadings.map((reading) => (
                <tr key={reading.id}>
                  <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">{reading.hostId}</td>
                  <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">{reading.reading} kWh</td>
                  <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">{reading.date}</td>
                  <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">{reading.status}</td>
                  <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">
                    {reading.status === 'unvalidated' && (
                      <button
                        onClick={() => handleValidate(reading.id)}
                        className="bg-accent text-white py-1 px-3 rounded-sm hover:bg-opacity-90 text-xs sm:text-sm"
                      >
                        Validate
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Card view for small screens */}
        <div className="sm:hidden">
          {unvalidatedReadings.map((reading) => (
            <div key={reading.id} className="border-b border-gray-200 p-4">
              <div className="flex justify-between items-center mb-2">
                <span className="font-medium text-sm">Host ID: {reading.hostId}</span>
                <span className={`px-2 py-1 rounded-sm-full text-xs ${
                  reading.status === 'validated' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                }`}>
                  {reading.status}
                </span>
              </div>
              <div className="text-sm mb-1">Reading: {reading.reading} kWh</div>
              <div className="text-sm mb-2">Date: {reading.date}</div>
              {reading.status === 'unvalidated' && (
                <button
                  onClick={() => handleValidate(reading.id)}
                  className="bg-accent text-white py-1 px-3 rounded-sm hover:bg-opacity-90 text-xs w-full"
                >
                  Validate
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MeterReadingManagement;