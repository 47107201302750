import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function Usage({ usageData }) {
  return (
    <div className="container mx-auto px-4">
      <h1 className="text-lg font-semibold mb-4 text-tertiary">Energy Usage</h1>
      <h2 className="text-xl font-semibold mb-4 text-secondary">Monthly Energy Production vs Consumption</h2>
      <div style={{ height: '400px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={usageData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="produced" stroke="#82ca9d" name="Energy generated (kWh)" />
            <Line type="monotone" dataKey="consumed" stroke="#8884d8" name="Energy consumed (kWh)" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default Usage;
