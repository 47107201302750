import React, { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from '../contexts/ModalContext';
import OutOfCapacityModal from './OutOfCapacityModal';
import { submitLead } from '../services/api';

// Add this CSS class to your global styles or component-specific styles
const hideNumberInputArrows = `
  /* Hide arrows for Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Hide arrows for Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

const slideUpAnimation = `
  @keyframes slideUp {
    from {
      transform: translateY(40px);
      opacity: 0;
    }
    to {
      transform: translateY(-10px);
      opacity: 1;
    }
  }

  .slide-up-enter {
    animation: slideUp 0.5s ease-out forwards;
  }
`;

const hoverDownEffect = `
  .hover-down {
    transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
  }
  .hover-down:hover {
    transform: translateY(4px);
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }
`;

const InfoCard = ({ title, value, className }) => (
  <div className={`
    bg-white rounded-sm shadow-md p-1.5 sm:p-3 
    flex flex-col justify-between 
    hover-down
    ${className}
  `}>
    <span className="text-xs sm:text-sm font-semibold text-tertiary">{title}</span>
    <span className="text-base sm:text-lg font-bold text-accent mt-0.5 sm:mt-1">
      {value === '' ? '฿0.00' : value}
    </span>
  </div>
);

function CapacitySelector({ capacity, setCapacity }) {
  const { t } = useTranslation();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const { isModalOpen, openModal, closeModal } = useModal();
  const sliderRef = useRef(null);
  const accentColor = '#FD9618'; 
  const secondaryColor = '#a59d9a'; 
  const [investment, setInvestment] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const MAX_CAPACITY_KW = 30; // Updated: 30 kW = 100%
  const PRICE_PER_KW = 31400; // 31,400 THB per kW
  
  const calculateLabelPosition = useCallback(() => {
    const percentage = (capacity / MAX_CAPACITY_KW) * 100;
    return `calc(${percentage}% + (${8 - percentage * 0.16}px))`;
  }, [capacity]);

  const calculateTrackBackground = useCallback(() => {
    const percentage = (capacity / MAX_CAPACITY_KW) * 100;
    return `linear-gradient(to right, ${accentColor} 0%, ${accentColor} ${percentage}%, ${secondaryColor} ${percentage}%, ${secondaryColor} 100%)`;
  }, [capacity, accentColor, secondaryColor]);

  const handleInvestmentChange = (e) => {
    // Get the raw input value without formatting
    let value = e.target.value.replace(/[^0-9]/g, '');
    
    // Handle empty or zero input
    if (!value || value === '0') {
      setInvestment('');
      setCapacity(0);
      return;
    }
    
    // Convert to number and update state
    const numericValue = value;
    setInvestment(numericValue);
    
    // Calculate capacity
    const newCapacity = parseFloat((parseFloat(numericValue) / PRICE_PER_KW).toFixed(2));
    setCapacity(newCapacity > MAX_CAPACITY_KW ? MAX_CAPACITY_KW : newCapacity);
  };

  const handleCapacityInputChange = (e) => {
    let value = e.target.value;
    
    // Allow empty input
    if (value === '') {
      setCapacity(0);
      setInvestment('');
      return;
    }

    // Remove any non-numeric characters except decimal point
    value = value.replace(/[^\d.]/g, '');
    
    // Ensure only one decimal point
    const parts = value.split('.');
    if (parts.length > 2) value = parts[0] + '.' + parts.slice(1).join('');
    
    // Convert to number and limit to 2 decimal places
    let newCapacity = parseFloat(value);
    
    // Handle NaN
    if (isNaN(newCapacity)) {
      newCapacity = 0;
    }
    
    // Enforce max capacity
    if (newCapacity > MAX_CAPACITY_KW) {
      newCapacity = MAX_CAPACITY_KW;
    }
    
    setCapacity(newCapacity);
    
    // Calculate investment
    if (newCapacity > 0) {
      const newInvestment = Math.round(newCapacity * PRICE_PER_KW).toString();
      setInvestment(newInvestment);
    } else {
      setInvestment('');
    }
  };

  const formatCurrency = (amount) => {
    if (!amount || amount === '') return '฿0.00';
    return `฿${Number(amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`;
  };

  const handleInvestNow = async (e) => {
    e.preventDefault();
    
    // Add validation checks and show error messages
    if (!fullName.trim()) {
      setNameError(true);
    }
    if (!email.trim()) {
      setEmailError(true);
    }
    
    if (!fullName.trim() || !email.trim()) {
      return;
    }

    try {
      setIsLoading(true);
      setIsButtonDisabled(true);

      const leadData = {
        fullname: fullName,
        email: email,
        role: "investor",
        mobile: "", 
        "selected-capacity": capacity.toString(),
        "price-per-kw": "31400",
        "annual-roi": "8.0",
        "helio-gains-region": "",
        "expirementuid": ""
      };

      const response = await submitLead(leadData);
      if (response && response.uuid) {
        await new Promise(resolve => setTimeout(resolve, 2500));
        
        console.log('Lead submitted successfully:', response.uuid);
        setIsLoading(false);
        openModal();
        setFullName('');
        setEmail('');
      } else {
        throw new Error('Invalid response from server');
      }

    } catch (error) {
      console.error('Investment submission error:', error);
      setIsLoading(false);
      setIsButtonDisabled(false);
      // You might want to add error feedback to the user here
    }
  };


  // Add this handler for the slider
  const handleCapacityChange = (e) => {
    const newCapacity = parseFloat(parseFloat(e.target.value).toFixed(2));
    setCapacity(newCapacity);
    
    // Calculate investment from capacity
    const newInvestment = Math.round(newCapacity * PRICE_PER_KW).toString();
    setInvestment(newInvestment);
  };

  // Update the formatting helper function
  const formatInvestmentDisplay = (value) => {
    if (!value || value === '') return '';
    // Format the number with commas but no decimal places
    return Number(value).toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true
    });
  };

  return (
    <>
      <style>{hideNumberInputArrows}</style>
      <style>{slideUpAnimation}</style>
      <style>{hoverDownEffect}</style>
      <div className="w-full mx-auto bg-white p-2 sm:p-4 shadow-lg">
        <div className="flex flex-col lg:flex-row gap-3 lg:gap-6">
          <div className="flex-1 flex flex-col">
            <div className="mb-2 sm:mb-6">
              <div className="hidden flex gap-2 sm:gap-4 mb-4">
                <div className="flex-1">
                  <label htmlFor="investment" className="block text-sm sm:text-base text-start font-medium text-gray-700 mb-1">
                    {t('capacitySelector.investmentAmount')}
                  </label>
                  <div className="flex">
                    <input
                      type="text"
                      id="investment"
                      className="w-full px-2 py-1.5 text-sm sm:text-base border border-gray-300 rounded-l-md focus:outline-none focus:ring-accent focus:border-accent"
                      value={formatInvestmentDisplay(investment)}
                      onChange={handleInvestmentChange}
                      placeholder="0.00"
                    />
                    <div className="px-2 py-1.5 text-white text-sm sm:text-base border border-l-0 border-gray-300 rounded-r-md bg-secondary">
                      THB
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <label htmlFor="capacity-input" className="block text-sm sm:text-base text-start font-medium text-gray-700 mb-1">
                    {t('capacitySelector.capacity')}
                  </label>
                  <input
                    type="text"
                    id="capacity-input"
                    className="w-full px-2 py-1.5 text-sm sm:text-base border border-gray-300 rounded-md focus:outline-none focus:ring-accent focus:border-accent"
                    value={capacity === 0 ? '' : capacity.toString()}
                    onChange={handleCapacityInputChange}
                    placeholder="Enter capacity"
                  />
                </div>
              </div>
              
              <label htmlFor="capacity-slider" className="block text-sm sm:text-base text-start font-medium text-gray-700">
                {t('capacitySelector.selectCapacity')}
              </label>
              <div className="relative mt-6 mb-2 px-2 sm:px-4">
                <div className="flex flex-col">
                  <div className="relative h-4" ref={sliderRef}>
                    <input
                      type="range"
                      id="capacity-slider"
                      min="0"
                      max={MAX_CAPACITY_KW}
                      step="0.5"
                      className="absolute top-1/2 left-0 w-full h-1 -translate-y-1/2 custom-range-input appearance-none bg-transparent"
                      value={capacity}
                      onChange={handleCapacityChange}
                      style={{
                        '--thumb-color': accentColor,
                        background: calculateTrackBackground(),
                      }}
                    />
                    <div 
                      className="absolute top-0 text-sm whitespace-nowrap"
                      style={{ 
                        left: calculateLabelPosition(), 
                        transform: 'translateX(-50%) translateY(-100%)',
                        color: accentColor,
                      }}
                    >
                      {Number(capacity).toFixed(2)} kW
                    </div>
                  </div>
                  <div className="flex justify-between mt-4">
                    <span className="text-tertiary text-md font-medium">0 kW</span>
                    <span className="text-tertiary text-md font-medium">30 kW</span>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="mt-1 sm:mt-2">
              <label htmlFor="fullName" className="block text-sm sm:text-base text-start font-medium text-gray-700 mb-1">
                {t('capacitySelector.name')}
              </label>
              <input
                type="text"
                id="fullName"
                className="w-full px-1 sm:px-2 py-1 text-sm sm:text-base border border-secondary rounded-sm focus:outline-none focus:ring-accent focus:border-accent"
                value={fullName}
                onChange={(e) => {
                  setFullName(e.target.value);
                  setNameError(false);
                }}
                placeholder={t('capacitySelector.enterFullName')}
                required
              />
              <div className="h-6">
                {nameError && (
                  <p className="text-sm text-red-600">{t('capacitySelector.nameRequired')}</p>
                )}
              </div>
            </div>

            <div className="mt-2 sm:mt-2">
              <label htmlFor="email" className="block text-sm sm:text-base text-start font-medium text-gray-700 mb-1">
                {t('capacitySelector.email')}
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-1 sm:px-2 py-1 text-sm sm:text-base border border-secondary rounded-sm focus:outline-none focus:ring-accent focus:border-accent"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(false);
                }}
                placeholder={t('capacitySelector.enterEmail')}
                required
              />
              <div className="h-6">
                {emailError && (
                  <p className="text-sm text-red-600">{t('capacitySelector.emailRequired')}</p>
                )}
              </div>
            </div>

            <div className="mt-2 text-sm text-gray-600 italic">
            {t('capacitySelector.customSolutionsMessage')}
            <br /> 
            <a href="mailto:info@heliogains.com" className="text-accent hover:underline">{t('capacitySelector.contactUs')}</a> {t('capacitySelector.aboveCapacityMessage')}
            </div>
          </div>
          
          <div className="bg-primary rounded-sm p-2 sm:p-4 flex-1 grid grid-cols-2 gap-2 sm:gap-3">
            <InfoCard 
              title={t('capacitySelector.totalInvestment')}
              value={formatCurrency(investment)}
              className="col-span-2 bg-accent text-white"
            />
            <InfoCard 
              title={t('capacitySelector.selectedCapacity')}
              value={`${capacity}kW`} 
              className="col-span-1" 
            />
            <InfoCard 
              title={t('capacitySelector.annualROI')}
              value="8.0%" 
              className="col-span-1" 
            />
            <InfoCard 
              title={t('capacitySelector.annualReturn')}
              value={formatCurrency(investment * 0.08)}
              className="col-span-2"
            />
            <button 
              className={`w-full text-white text-sm sm:text-base font-medium py-1.5 sm:py-2 px-3 sm:px-6 rounded-sm 
                shadow-md
                hover-down
                bg-accent hover:bg-opacity-80
                ${isButtonDisabled ? 'bg-opacity-50 cursor-not-allowed hover:transform-none' : ''} 
                col-span-2 mt-2 sm:mt-2`}
              onClick={handleInvestNow}
              disabled={isButtonDisabled}
            >
              {isButtonDisabled ? t('capacitySelector.investmentSubmitted') : t('capacitySelector.investNow')}
            </button>
          </div>
        </div>

        {isLoading && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-transparent p-5 rounded-sm shadow-xl">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-accent"></div>
            </div>
          </div>
        )}
        <OutOfCapacityModal isOpen={isModalOpen} onClose={closeModal} />
      </div>
    </>
  )
}

export default CapacitySelector
