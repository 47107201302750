import React from 'react';
import { FaSolarPanel, FaMoneyBillWave, FaUsers, FaChartLine } from 'react-icons/fa';

function setIcon(title)
{
  if (title === "Total Energy Generated") {
    return <FaSolarPanel className="text-2xl mr-2" />;
  }
  else if (title === "Total Revenue") {
    return <FaMoneyBillWave className="text-2xl mr-2" />;
  }
  else if (title === "Total Investors") {
    return <FaUsers className="text-2xl mr-2" />;
  }
  else if (title === "Total Households") {
    return <FaChartLine className="text-2xl mr-2" />;
  }
}
function setStyling(title)
{
  if (title === "Total Energy Generated") {
    return "bg-primary text-tertiary";
  }
  else if (title === "Total Revenue") {
    return "bg-primary text-tertiary";
  }
  else if (title === "Total Investors") {
    return "bg-primary text-tertiary";
  }
  else if (title === "Total Households") {
      return "bg-primary text-tertiary";
  }
}

function StatisticsBox({  title, value}) {
  return (
    <div className={`${setStyling(title)} p-4 rounded-sm shadow-md`}>
      <div className="flex items-center mb-2">
        {setIcon(title)}
        <h2 className="text-lg font-semibold">{title}</h2>
      </div>
      <p className="text-2xl font-bold">{value}</p>
    </div>
  );
}

export default StatisticsBox;
