import React from 'react'
import { Link } from 'react-router-dom'

function Verified() {
  return (
    <div className="min-h-screen flex flex-col bg-cover bg-center" style={{ backgroundImage: "url('/img/solar-panels.jpg')" }}>
      <div className="fixed inset-0 bg-black bg-opacity-50"></div>
      
      <div className="flex-grow flex justify-center items-center px-4 py-8 relative z-10">
        <div className="bg-primary p-8 rounded-md shadow-xl w-full max-w-lg animate__animated animate__zoomIn animate__slow">
          <h1 className="text-2xl sm:text-3xl font-bold mb-6 text-tertiary text-center">
            Thank You for Verifying Your Account!
          </h1>

          <div className="space-y-4 mb-8">
            <p className="text-center text-sm sm:text-base">
              Your account is now being verified by our team. This process typically takes 24-48 hours.
            </p>
            <p className="text-center text-sm sm:text-base">
              Once verified, you'll receive an email confirmation and can start investing in solar projects.
            </p>
            <p className="text-center text-tertiary font-medium">
              🌞
            </p>
          </div>

          <Link 
            to="/login" 
            className="block w-full bg-accent text-white font-semibold py-3 px-4 rounded text-center hover:bg-opacity-90 transition duration-300"
          >
            Proceed to Login
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Verified
