import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const InvestorGrowth = ({ dashboardData }) => {
  return (
    <div>
        <h2 className="text-xl font-semibold mb-4 text-tertiary">Investor Growth</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={dashboardData.investorGrowth}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="investors" stroke="#ffc658" name="Total Investors" />
            </LineChart>
          </ResponsiveContainer>
    </div>
  )
}

export default InvestorGrowth