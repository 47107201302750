export const mockData = {
  totalEnergyGeneratedValue: 912500,
  totalEnergyGeneratedTitle: "Total Energy Generated",
  totalRevenue: 2948125,
  totalInvestors: 500,
  totalHouseholds: 100,
  projectTypes: [
    { name: 'Large-scale', value: 40 },
    { name: 'Residential', value: 35 },
    { name: 'Industrial', value: 25 }
  ],
  monthlyEnergy: [
    { month: 'Jan', energy: 70000 },
    { month: 'Feb', energy: 75000 },
    { month: 'Mar', energy: 80000 },
    { month: 'Apr', energy: 85000 },
    { month: 'May', energy: 90000 },
    { month: 'Jun', energy: 95000 }
  ],
  monthlyRevenue: [
    { month: 'Jan', revenue: 226000 },
    { month: 'Feb', revenue: 242000 },
    { month: 'Mar', revenue: 258000 },
    { month: 'Apr', revenue: 274000 },
    { month: 'May', revenue: 290000 },
    { month: 'Jun', revenue: 306000 }
  ],
  investorGrowth: [
    { month: 'Jan', investors: 300 },
    { month: 'Feb', investors: 350 },
    { month: 'Mar', investors: 400 },
    { month: 'Apr', investors: 430 },
    { month: 'May', investors: 470 },
    { month: 'Jun', investors: 500 }
  ]
};

export const fetchDashboardData = async () => {
  // Simulated API call
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockData);
    }, 1000); // Simulate a 1-second delay
  });
};

export const calculateSupplyVsDemandData = () => {
  const energyPerKW = 1825; // kWh per year
  const kWPerHousehold = 5;
  const initialHouseholds = 100;
  const monthlyGrowthRate = 0.05; // 5% monthly growth

  // Seasonal factors for energy production (higher in summer, lower in winter)
  const seasonalFactors = [0.8, 0.9, 1.0, 1.1, 1.2, 1.3, 1.2, 1.1, 1.0, 0.9, 0.8, 0.7];

  // Random fluctuation for investor demand
  const getRandomFluctuation = () => 0.9 + Math.random() * 0.2; // Random factor between 0.9 and 1.1

  return Array.from({ length: 12 }, (_, index) => {
    const month = new Date(2023, index, 1).toLocaleString('default', { month: 'short' });
    const households = Math.round(initialHouseholds * Math.pow(1 + monthlyGrowthRate, index));
    
    // Apply seasonal factor to energy production
    const totalEnergy = households * kWPerHousehold * (energyPerKW / 12) * seasonalFactors[index];
    
    const baseRevenue = households * 29481.25 / 12;
    // Apply random fluctuation to investor demand
    const investorDemand = baseRevenue * 0.8 * getRandomFluctuation();

    return {
      month,
      supply: totalEnergy,
      demand: investorDemand
    };
  });
};
