import React from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import { UserGroupIcon, BoltIcon, CheckCircleIcon } from '@heroicons/react/24/outline';

const stats = [
  {
    id: 1,
    name: 'Total Leads',
    stat: '1,247',
    periodStats: {
      weekly: '156',
      today: '23'
    },
    icon: UserGroupIcon,
    change: '12%',
    changeType: 'increase'
  },
  {
    id: 2,
    name: 'Capacity Requested',
    stat: '2,580 kW',
    periodStats: {
      weekly: '425 kW',
      today: '85 kW'
    },
    icon: BoltIcon,
    change: '8.1%',
    changeType: 'increase'
  },
  {
    id: 3,
    name: 'Confirmed Leads',
    stat: '847',
    periodStats: {
      weekly: '92',
      today: '14'
    },
    icon: CheckCircleIcon,
    change: '0%',
    changeType: 'decrease'
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function CRM() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6 text-tertiary">Customer Relationship Management</h1>

      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-sm bg-primary px-4 pb-12 pt-5 shadow-md sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-sm bg-tertiary p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 truncate text-md font-medium text-gray-600">{item.name}</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-tertiary">{item.stat}</p>
              <p
                className={classNames(
                  item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                  'ml-2 flex items-baseline text-sm font-semibold'
                )}
              >
                {item.changeType === 'increase' ? (
                  <ArrowUpIcon className="h-5 w-5 shrink-0 self-center text-green-500" aria-hidden="true" />
                ) : (
                  <ArrowDownIcon className="h-5 w-5 shrink-0 self-center text-red-500" aria-hidden="true" />
                )}
                <span className="sr-only">
                  {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by
                </span>
                {item.change}
              </p>
              <div className="absolute inset-x-0 bottom-0 bg-secondary bg-opacity-10 px-4 py-3 sm:px-6">
                <div className="flex justify-between divide-x divide-gray-400">
                  <div className="text-center flex-1 pr-4">
                    <p className="text-sm text-gray-600">This Week</p>
                    <p className="text-tertiary font-semibold text-base">{item.periodStats.weekly}</p>
                  </div>
                  <div className="text-center flex-1 pl-4">
                    <p className="text-sm text-gray-600">Today</p>
                    <p className="text-tertiary font-semibold text-base">{item.periodStats.today}</p>
                  </div>
                </div>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}

export default CRM; 