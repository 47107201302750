import React, { useEffect } from 'react';
import SimpleHeader from '../components/SimpleHeader';
import { trackClick } from '../utils/analytics';

function Terms() {
  useEffect(() => {
    trackClick('terms');
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <SimpleHeader />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-6 text-tertiary">Terms of Service</h1>
        <div className="bg-white rounded-sm shadow-md p-6">
          <h2 className="text-xl font-semibold mb-4 text-secondary">1. Introduction</h2>
          <p className="mb-4">
            Welcome to HelioGains. By using our service, you agree to these terms. Please read them carefully.
          </p>

          <h2 className="text-xl font-semibold mb-4 text-secondary">2. Using our Services</h2>
          <p className="mb-4">
            You must follow any policies made available to you within the Services.
          </p>

          <h2 className="text-xl font-semibold mb-4 text-secondary">3. Investment Risks</h2>
          <p className="mb-4">
            All investments carry risks. Please carefully consider your investment objectives and risk tolerance before investing.
          </p>

          <h2 className="text-xl font-semibold mb-4 text-secondary">4. Privacy & Security</h2>
          <p className="mb-4">
            Your use of our services is subject to our Privacy Policy. By using HelioGains, you agree to our privacy practices.
          </p>

          <h2 className="text-xl font-semibold mb-4 text-secondary">5. Modifications</h2>
          <p className="mb-4">
            We may modify these terms or any additional terms that apply to a Service to reflect changes in the law or changes to our Services.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Terms;
